import { ColorScheme, ColorSchemeProvider, MantineProvider, createEmotionCache } from '@mantine/core'
import { useColorScheme, useLocalStorage } from '@mantine/hooks'
import { ModalsProvider } from '@mantine/modals'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import rtlPlugin from 'stylis-plugin-rtl'
import { ErrorBoundary } from './components/error-boundary'
import { MyIntlProvider } from './components/intl'
import AboutPage from './components/pages/about'
import ChatPage from './components/pages/chat'
import LandingPage from './components/pages/landing'
import Tour from './components/tour'
import { AppContextProvider } from './core/context'
import './index.scss'
import store, { persistor } from './store'
import theme from './theme'

const rtlCache = createEmotionCache({
  key: 'mantine-rtl',
  stylisPlugins: [rtlPlugin],
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function App() {
  const preferredColorScheme: ColorScheme = useColorScheme()
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
  })
  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme = value ?? (colorScheme === 'dark' ? 'light' : 'dark')
    console.log('toggleColorScheme', nextColorScheme)
    setColorScheme(nextColorScheme)
  }
  useEffect(() => {
    // override when color scheme changes on os:
    preferredColorScheme !== colorScheme && setColorScheme(preferredColorScheme)
  }, [preferredColorScheme])
  const [rtl, setRtl] = useState(false)
  return (
    <React.StrictMode>
      <BrowserRouter>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            withNormalizeCSS
            withGlobalStyles
            theme={{ ...theme, colorScheme, dir: rtl ? 'rtl' : 'ltr' }}
            emotionCache={rtl ? rtlCache : undefined}
          >
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <MyIntlProvider setRtl={setRtl}>
                  <AppContextProvider>
                    <ErrorBoundary>
                      <Tour />
                      <ModalsProvider>
                        <Routes>
                          <Route path="/chat/:id" element={<ChatPage />} />
                          <Route path="/s/:id" element={<ChatPage share={true} />} />
                          <Route path="/s/:id/*" element={<ChatPage share={true} />} />
                          <Route path="/" index element={<LandingPage />} />
                          <Route path="/about" index element={<AboutPage />} />
                        </Routes>
                      </ModalsProvider>
                    </ErrorBoundary>
                  </AppContextProvider>
                </MyIntlProvider>
              </PersistGate>
            </Provider>
          </MantineProvider>
        </ColorSchemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  )
}

export default root.render(<App />)
