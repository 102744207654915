import styled from '@emotion/styled'
import { SpotlightProvider } from '@mantine/spotlight'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useChatSpotlightProps } from '../spotlight'
import { CreateAccountModal, LoginModal } from './auth-modals'
import MessageInput from './input'
import { HeaderProps, default as Layout } from './layout'
import SettingsDrawer from './settings'
import AudioControls from './tts-controls'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  // background: #292933;
  // color: white;
`

const Main = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;

  @media (min-height: 30em) {
    overflow: hidden;
  }
`

export const usePage = () => {
  const { pathname } = useLocation()
  const isHome = pathname === '/'
  const isShare = pathname.startsWith('/s/')
  return useMemo(
    () => ({
      isHome,
      isShare,
    }),
    [pathname],
  )
}

export function Page({
  id,
  headerProps,
  showFooter = true,
  title,
  children,
}: {
  id: string
  headerProps?: HeaderProps
  showFooter?: boolean
  title?: string | null
  children: any
}) {
  const spotlightProps = useChatSpotlightProps()
  const { formatMessage } = useIntl()
  return (
    <SpotlightProvider {...spotlightProps}>
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          {formatMessage({
            defaultMessage: 'Ask AI Assistant',
            description: 'HTML title tag',
          })}
        </title>
      </Helmet>

      <Container>
        <Main key={id}>
          <Layout share={headerProps?.share} canShare={headerProps?.canShare} onShare={headerProps?.onShare}>
            {children}
            {showFooter && (
              <>
                <AudioControls />
                <MessageInput />
              </>
            )}
          </Layout>
          <SettingsDrawer />
          <LoginModal />
          <CreateAccountModal />
        </Main>
      </Container>
    </SpotlightProvider>
  )
}
