import { defineMessages, MessageDescriptor } from 'react-intl'

const msgs: Record<string, MessageDescriptor> = defineMessages({
  speech: {
    defaultMessage: 'Speech',
    description: 'The ability to speak',
    id: 'speech',
  },
  ui: {
    defaultMessage: 'UI',
    description: 'User Interface',
    id: 'ui',
  },
  user: {
    defaultMessage: 'User',
    id: 'user',
  },
  'auto-scroll': {
    defaultMessage: 'Autoscroll',
    id: 'auto-scroll',
  },
  'auto-scroll-when-opening-chat': {
    defaultMessage: 'Auto-scroll to the bottom of the page when opening a chat',
    id: 'auto-scroll-when-opening-chat',
  },
  'auto-scroll-while-generating': {
    defaultMessage: 'Auto-scroll while generating a response',
    id: 'auto-scroll-while-generating',
  },
  input: {
    defaultMessage: 'Message Input',
    id: 'input',
  },
  'submit-on-enter': {
    defaultMessage: 'Enable/disable submit message when Enter is pressed',
    id: 'submit-on-enter',
  },
  'submit-on-enter-quicksetting': {
    defaultMessage: 'Submit on Enter',
    id: 'submit-on-enter',
  },
  tts: {
    defaultMessage: 'Text-to-Speech',
    id: 'tts',
  },
  'tts--autoplay': {
    defaultMessage: 'Autoplay audio',
    id: 'tts--autoplay',
  },
  'web-speech': {
    defaultMessage: "Your Browser's Built-In Text-to-Speech",
    id: 'web-speech',
  },
  autoplay: {
    defaultMessage: 'Read messages aloud automatically',
    id: 'autoplay',
  },
  service: {
    defaultMessage: 'Choose a Text-to-Speech Provider',
    id: 'service',
  },
  'speech-recognition': {
    defaultMessage: 'Microphone',
    id: 'speech-recognition',
  },
  'use-whisper': {
    defaultMessage: 'Use the OpenAI Whisper API for speech recognition',
    id: 'use-whisper',
  },
  'show-microphone': {
    defaultMessage: 'Show microphone in message input',
    id: 'show-microphone',
  },
  elevenlabs: {
    defaultMessage: 'ElevenLabs Text-to-Speech',
    id: 'elevenlabs',
  },
  voice: {
    defaultMessage: 'Voice',
    id: 'voice',
  },
  'input--submit-on-enter': {
    defaultMessage: 'Submit on Enter',
    id: 'input--submit-on-enter',
  },
  'quick-settings': {
    defaultMessage: 'Quick Settings',
    id: 'quick-settings',
  },
  'import-export': {
    defaultMessage: 'Import/Export',
    id: 'import-export',
  },
})

export default msgs
