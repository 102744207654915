import { Modal } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export default function ErrorModal({ onClose }) {
  return (
    <Modal opened onClose={onClose} withCloseButton={true}>
      <h1>
        <FormattedMessage defaultMessage={'Error'} />
      </h1>
      <p>
        <FormattedMessage defaultMessage={'Could not get a response in time. Please try again with "Regenerate".'} />
      </p>
    </Modal>
  )
}
