import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppContext } from '../context'
import { RenderProps } from './render-props'

export function useOption<T = any>(
  groupID: string,
  optionID: string,
  chatID?: string,
): [T, (value: T) => void, RenderProps, number] {
  const context = useAppContext()
  const { chatManager } = context

  const [value, setValue] = useState(chatManager.options.getValidatedOption(groupID, optionID, chatID))
  const [version, setVersion] = useState(0)

  const timer = useRef<NodeJS.Timeout>()

  const onUpdate = useCallback(
    (updatedGroupID: string) => {
      if (groupID === updatedGroupID) {
        setValue(chatManager.options.getValidatedOption(groupID, optionID, chatID))
        setVersion((v) => v + 1)
      } else {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          setValue(chatManager.options.getValidatedOption(groupID, optionID, chatID))
          setVersion((v) => v + 1)
        }, 500)
      }
    },
    [groupID, chatManager.options, optionID, chatID],
  )

  useEffect(() => {
    chatManager.on('plugin-options-update', onUpdate)
    return () => {
      chatManager.off('plugin-options-update', onUpdate)
    }
  }, [chatID, chatManager, onUpdate])

  const setOptionValue = useCallback(
    (_value: any) => {
      chatManager.options.setOption(groupID, optionID, _value, chatID)
    },
    [chatManager.options, groupID, optionID, chatID],
  )

  const option = chatManager.options.findOption(groupID, optionID)!

  return [
    value,
    setOptionValue,
    typeof option.renderProps === 'function'
      ? option.renderProps(value, chatManager.options, context)
      : option.renderProps,
    version,
  ]
}
