import styled from '@emotion/styled'
import { Loader } from '@mantine/core'
import React, { Suspense, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import slugify from 'slugify'

import { backend } from '../../core/backend'
import { useOption } from '../../core/options/use-option'
import { TTSContextProvider } from '../../core/tts/use-tts'
import { useMessage } from '../message'
import { Page, usePage } from '../page'

const Message = React.lazy(() => import(/* webpackPreload: true */ '../message'))

const Messages = styled.div`
  @media (min-height: 30em) {
    max-height: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }
  display: flex;
  flex-direction: column;
`

const EmptyMessage = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  line-height: 1.7;
  gap: 1rem;
  min-height: 10rem;
`

export default function ChatPage({ share }: any) {
  const { id } = useParams()
  const { isShare } = usePage()
  const { currentChat, generating } = useMessage()

  const [autoScrollWhenOpeningChat] = useOption('auto-scroll', 'auto-scroll-when-opening-chat')
  const [autoScrollWhileGenerating] = useOption('auto-scroll', 'auto-scroll-while-generating')

  useEffect(() => {
    if (share || !currentChat.chatLoadedAt) {
      return
    }

    const shouldScroll = autoScrollWhenOpeningChat || Date.now() - currentChat.chatLoadedAt > 5000

    if (!shouldScroll) {
      return
    }

    const container = document.querySelector('#messages') as HTMLElement
    const messages = document.querySelectorAll('#messages .message')

    if (messages.length) {
      const latest = messages[messages.length - 1] as HTMLElement
      const offset = Math.max(0, latest.offsetTop - 100)
      setTimeout(() => {
        container?.scrollTo({ top: offset, behavior: 'smooth' })
      }, 100)
    }
  }, [currentChat?.chatLoadedAt, currentChat?.messagesToDisplay.length, share, autoScrollWhenOpeningChat])

  const autoScroll = useCallback(() => {
    if (generating && autoScrollWhileGenerating) {
      const container = document.querySelector('#messages') as HTMLElement
      container?.scrollTo({ top: 999999, behavior: 'smooth' })
      container?.parentElement?.scrollTo({ top: 999999, behavior: 'smooth' })
    }
  }, [generating, autoScrollWhileGenerating])
  useEffect(() => {
    const timer = setInterval(() => autoScroll(), 1000)
    return () => {
      clearInterval(timer)
    }
  }, [autoScroll])

  const { messagesToDisplay } = currentChat

  const shouldShowChat = id && currentChat.chat && !!messagesToDisplay.length

  return (
    <TTSContextProvider>
      <Page
        id={id || 'landing'}
        title={id && messagesToDisplay.length ? currentChat.chat?.title : undefined}
        headerProps={{
          share: isShare,
          canShare: messagesToDisplay.length > 1,
          onShare: async () => {
            if (currentChat.chat) {
              const id = await backend.current?.shareChat(currentChat.chat)
              if (id) {
                const slug = currentChat.chat.title ? `/${slugify(currentChat.chat.title.toLocaleLowerCase())}` : ''
                const url = `${window.location.origin}/s/${id}${slug}`
                await navigator.share?.({
                  title: currentChat.chat.title || undefined,
                  url,
                })
              }
            }
          },
        }}
      >
        <Suspense
          fallback={
            <Messages id="messages">
              <EmptyMessage>
                <Loader variant="dots" />
              </EmptyMessage>
            </Messages>
          }
        >
          <Messages id="messages">
            {shouldShowChat && (
              <div style={{ paddingBottom: '4.5rem' }}>
                {messagesToDisplay.map((message) => (
                  <Message
                    key={`${id}:${message.id}`}
                    message={message}
                    share={share}
                    last={currentChat.chat!.messages.leafs.some((n) => n.id === message.id)}
                  />
                ))}
              </div>
            )}
            {!shouldShowChat && (
              <EmptyMessage>
                <Loader variant="dots" />
              </EmptyMessage>
            )}
          </Messages>
        </Suspense>
      </Page>
    </TTSContextProvider>
  )
}
