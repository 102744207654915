import { MessageNode, MessageTree } from './message-tree'

export interface UserSubmittedMessage {
  chatID: string
  parentID?: string
  content: string
  matchInputLang?: boolean
}

export interface Message extends UserSubmittedMessage {
  id: string
  timestamp: number
  role: string
  model?: string
  done?: boolean
  sources?: string
}

export interface OpenAIMessage {
  role: string
  content: string
}

export function getOpenAIMessageFromMessage(message: Message): OpenAIMessage {
  return {
    role: message.role,
    content: message.content,
  }
}

export interface Chat {
  id: string
  messages: MessageTree
  metadata?: Record<string, any>
  pluginOptions?: Record<string, any>
  title?: string | null
  created: number
  updated: number
  deleted?: boolean
}

export function serializeChat(chat: Chat): string {
  return JSON.stringify({
    ...chat,
    messages: chat.messages.serialize(),
  })
}

export function deserializeChat(serialized: string) {
  const chat: any = JSON.parse(serialized) as Chat
  chat.messages = new MessageTree(chat.messages as Message[] | MessageNode[])
  return chat as Chat
}
