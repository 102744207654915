import styled from '@emotion/styled'
import { ActionIcon, Button, Loader, Popover, Textarea } from '@mantine/core'
import { HotkeyItem, getHotkeyHandler, useHotkeys } from '@mantine/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useChat } from '../core/chat/use-chat'
import { useAppContext } from '../core/context'
import { useOption } from '../core/options/use-option'
import { speechRecognition, supportsSpeechRecognition } from '../core/speech-recognition-types'
import { useAppDispatch, useAppSelector } from '../store'
import { selectMessage, setMessage } from '../store/message'
import useWhisper from './hooks/useWhisper'
import { useMessage } from './message'
import { usePage } from './page'
import QuickSettings from './quick-settings'

const Container = styled.div`
  padding: 1rem 1rem 0 1rem;

  .inner {
    max-width: 50rem;
    margin: auto;
    text-align: right;
  }

  .settings-button {
    margin: 0.5rem -0.4rem 0.5rem 1rem;
    font-size: 0.7rem;
    // color: #999;
  }
`

const RightSection = styled.div`
  opacity: 0.8;
`

export declare type OnSubmit = (name?: string) => Promise<boolean>

export interface MessageInputProps {
  disabled?: boolean
}

export default function MessageInput(props: MessageInputProps) {
  const message = useAppSelector(selectMessage)
  const [recording, setRecording] = useState(false)
  const [speechError, setSpeechError] = useState<string | null>(null)
  const [useOpenAIWhisper] = useOption<boolean>('speech-recognition', 'use-whisper')
  const [initialMessage, setInitialMessage] = useState('')
  const { transcribing, transcript, startRecording, stopRecording } = useWhisper()
  const { chatManager } = useAppContext()
  const { isShare } = usePage()
  const { generating, id, onSubmit } = useMessage()
  const currentChat = useChat(chatManager, id, isShare)
  const dispatch = useAppDispatch()
  const { formatMessage, locale } = useIntl()
  const [showMicrophoneButton] = useOption<boolean>('speech-recognition', 'show-microphone')
  const [submitOnEnter] = useOption<boolean>('input', 'submit-on-enter')

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const msg = e.target.value
      setInitialMessage(msg)
      // setTranscript(msg)
      dispatch(setMessage(msg))
    },
    [dispatch],
  )

  const onSubmitMessage = useCallback(() => {
    setSpeechError(null)
    onSubmit()
  }, [onSubmit])

  const { pathname } = useLocation()

  const onSpeechError = useCallback(
    (e: any) => {
      console.error('speech recognition error', e)
      setSpeechError(e.message as string)

      try {
        speechRecognition?.stop()
      } catch (e) {}

      try {
        stopRecording()
      } catch (e) {}

      setRecording(false)
    },
    [stopRecording],
  )

  const onHideSpeechError = useCallback(() => setSpeechError(null), [])

  const onSpeechStart = useCallback(async () => {
    let granted = false
    let denied = false

    try {
      const result = await navigator.permissions.query({ name: 'microphone' as any })
      if (result.state == 'granted') {
        granted = true
      } else if (result.state == 'denied') {
        denied = true
      }
    } catch (e) {}

    if (!granted && !denied) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        stream.getTracks().forEach((track) => track.stop())
        granted = true
      } catch (e) {
        denied = true
      }
    }

    if (denied) {
      onSpeechError(new Error('speech permission was not granted'))
      return
    }

    try {
      if (!recording) {
        setRecording(true)

        if (useOpenAIWhisper || !supportsSpeechRecognition) {
          setInitialMessage(message)
          await startRecording()
        } else if (speechRecognition) {
          const initialMessage = message

          speechRecognition.continuous = true
          speechRecognition.interimResults = true

          speechRecognition.onresult = (event) => {
            let transcript = ''
            for (let i = 0; i < event.results.length; i++) {
              if (event.results[i].isFinal && event.results[i][0].confidence) {
                transcript += event.results[i][0].transcript
              }
            }
            dispatch(setMessage(`${initialMessage} ${transcript}`))
          }

          speechRecognition.start()
        } else {
          onSpeechError(new Error('not supported'))
        }
      } else {
        if (useOpenAIWhisper || !supportsSpeechRecognition) {
          stopRecording()
          setTimeout(() => setRecording(false), 500)
        } else if (speechRecognition) {
          speechRecognition.stop()
          setRecording(false)
        } else {
          onSpeechError(new Error('not supported'))
        }
      }
    } catch (e) {
      onSpeechError(e)
    }
  }, [recording, message, dispatch, onSpeechError, setInitialMessage])

  useEffect(() => {
    if (transcript === '') return
    const nextMessage = `${initialMessage} ${transcript}`
    setInitialMessage(nextMessage)
    dispatch(setMessage(nextMessage))
  }, [transcript])

  // useEffect(() => {
  //   if (useOpenAIWhisper || !supportsSpeechRecognition) {
  //     if (!transcribing && transcript) {
  //       dispatch(setMessage(initialMessage))
  //     }
  //   }
  // }, [initialMessage, transcript, recording, transcribing, useOpenAIWhisper, dispatch])

  useHotkeys([['n', () => document.querySelector<HTMLTextAreaElement>('#message-input')?.focus()]])

  const blur = useCallback(() => {
    document.querySelector<HTMLTextAreaElement>('#message-input')?.blur()
  }, [])

  const rightSection = useMemo(() => {
    return (
      <RightSection>
        {generating && (
          <>
            <ActionIcon
              variant="subtle"
              size="xs"
              onClick={() => {
                chatManager.cancelReply(currentChat.chat?.id, currentChat.leaf!.id)
              }}
            >
              <FormattedMessage
                defaultMessage={'Cancel'}
                description="Label for the button that can be clicked while the AI is generating a response to cancel generation"
              />
            </ActionIcon>
            <Loader size="xs" style={{ padding: '0 0.8rem 0 0.5rem' }} />
          </>
        )}
        {!generating && (
          <>
            {showMicrophoneButton && (
              <Popover position="bottom" withArrow shadow="md" opened={speechError !== null}>
                {/* {locale !== env.REACT_APP_DOCS_LANG_CODE && (
                  <Popover.Target>
                    <ActionIcon
                      data-id="input-match-lang"
                      className={`selectable ${matchInputLang ? 'selected' : undefined}`}
                      title={formatMessage(
                        {
                          defaultMessage:
                            'If checked, the AI will try to find documentation in the chosen language (<p>English</p>).',
                        },
                        {
                          p: () => languages[locale][1],
                        },
                      )}
                      size="md"
                      onClick={() => {
                        setMatchInputLang(!matchInputLang)
                      }}
                      variant="subtle"
                    >
                      <i className="fa-solid fa-language" />
                    </ActionIcon>
                  </Popover.Target>
                )} */}
                <Popover.Target>
                  <ActionIcon
                    size="xl"
                    onClick={() => onSpeechStart()}
                    variant="subtle"
                    data-id="input-microphone"
                    className={`selectable ${recording ? 'selected' : undefined}`}
                  >
                    {transcribing && <Loader size="xs" />}
                    {!transcribing && <i className="fa fa-microphone" style={{ fontSize: '90%' }} />}
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <p
                      style={{
                        fontFamily: `"Work Sans", sans-serif`,
                        fontSize: '0.9rem',
                        textAlign: 'center',
                        marginBottom: '0.5rem',
                      }}
                    >
                      Sorry, an error occured trying to record audio.
                    </p>
                    <Button size="sm" fullWidth onClick={onHideSpeechError} variant="light">
                      Close
                    </Button>
                  </div>
                </Popover.Dropdown>
              </Popover>
            )}
            <ActionIcon size="xl" onClick={onSubmitMessage}>
              <i className="fa fa-paper-plane" style={{ fontSize: '90%' }} />
            </ActionIcon>
          </>
        )}
      </RightSection>
    )
  }, [
    locale,
    recording,
    transcribing,
    onSubmitMessage,
    onSpeechStart,
    props.disabled,
    generating,
    speechError,
    onHideSpeechError,
    showMicrophoneButton,
  ])

  const disabled = generating

  const isLandingPage = pathname === '/'
  if (isShare || (!isLandingPage && !id)) {
    return null
  }

  const hotkeyHandler = useMemo(() => {
    const keys: HotkeyItem[] = [
      ['Escape', blur, { preventDefault: true }],
      ['ctrl+Enter', onSubmitMessage, { preventDefault: true }],
    ]
    if (submitOnEnter) {
      keys.unshift(['Enter', onSubmitMessage, { preventDefault: true }])
    }
    // until this is fixed: https://github.com/mantinedev/mantine/issues/4404
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const handler = getHotkeyHandler(keys as any)
    return handler
  }, [onSubmit, blur, submitOnEnter])

  return (
    <Container>
      <div className="inner">
        <Textarea
          disabled={props.disabled || disabled}
          id="message-input"
          autosize
          minRows={3}
          maxRows={5}
          maxLength={400}
          placeholder={formatMessage({ defaultMessage: 'Enter a message here...' })}
          value={message}
          onChange={onChange}
          rightSection={rightSection}
          rightSectionWidth={generating ? 165 : 44}
          onKeyDown={hotkeyHandler}
        />
        <QuickSettings />
      </div>
    </Container>
  )
}
