import { Button, FileButton } from '@mantine/core'
import { useCallback, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { importChat } from '../../core/chat/chat-persistance'
import { Chat, serializeChat } from '../../core/chat/types'
import { useAppContext } from '../../core/context'
import SettingsOption from './option'
import SettingsTab from './tab'

export default function UserOptionsTab() {
  const { formatMessage } = useIntl()
  const { chatManager } = useAppContext()
  const { doc } = chatManager
  const getData = useCallback(() => {
    const chats = chatManager.all()
    return chats.map((chat) => serializeChat(chat))
  }, [chatManager])

  const [importedChats, setImportedChats] = useState<number | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleExport = useCallback(() => {
    const data = getData()
    const json = JSON.stringify(data)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = 'ai-assistant.json'
    link.click()
  }, [getData])

  const handleImport = useCallback(
    (file: File) => {
      try {
        const reader = new FileReader()
        reader.onload = (e) => {
          const json = e.target?.result as string
          const data = JSON.parse(json) as Chat[]
          if (data.length > 0) {
            chatManager.doc.transact(() => {
              for (const chat of data) {
                try {
                  importChat(doc, chat)
                } catch (e) {
                  console.error(e)
                }
              }
            })
            setImportedChats(data.length)
            setErrorMessage(null)
          } else {
            setErrorMessage('The imported file does not contain any chat data.')
          }
        }
        reader.readAsText(file)
      } catch (error) {
        setErrorMessage('Failed to import chat data.')
      }
    },
    [doc],
  )

  const successMessage = importedChats ? (
    <div style={{ color: 'green' }}>
      <i className="fa fa-check-circle"></i>
      <span style={{ marginLeft: '0.5em' }}>Imported {importedChats} chat(s)</span>
    </div>
  ) : null

  const errorMessageElement = errorMessage ? <div style={{ color: 'red' }}>{errorMessage}</div> : null
  const heading = formatMessage({ defaultMessage: 'Import and Export' })

  return (
    <SettingsTab name="user">
      <SettingsOption heading={heading}>
        <div>
          <Button
            variant="filled"
            onClick={handleExport}
            style={{
              marginRight: '1rem',
            }}
          >
            <FormattedMessage
              defaultMessage="Export"
              description="Label for a button that will trigger an export of the user data"
            />
          </Button>
          <FileButton onChange={handleImport} accept=".json">
            {(props) => (
              <Button variant="filled" {...props}>
                <FormattedMessage
                  defaultMessage="Import"
                  description="Label for a button that will aid in importing user data"
                />
              </Button>
            )}
          </FileButton>
        </div>
        {successMessage}
        {errorMessageElement}
      </SettingsOption>
    </SettingsTab>
  )
}
