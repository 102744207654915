import Plugin from '../plugins'
import { Voice } from '../tts/types'

export default class TTSPlugin<T = any> extends Plugin<T> {
  getCurrentVoice(): Voice {
    throw new Error('not implemented')
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  async speakToBuffer(_text: string, _voice?: Voice): Promise<ArrayBuffer | null | undefined> {
    throw new Error('not implemented')
  }
}
