import { SpotlightAction } from '@mantine/spotlight'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppContext } from './core/context'

export function useChatSpotlightProps() {
  const navigate = useNavigate()
  const { chatManager } = useAppContext()
  const [actions, setActions] = useState<SpotlightAction[]>([])
  const { formatMessage, locale } = useIntl()

  const [_version, setVersion] = useState(0)

  useEffect(() => {
    const handleUpdate = () => {
      setVersion((v) => v + 1)
      const _actions = chatManager.searchChats('').map((result) => ({
        ...result,
        onTrigger: () => navigate(`/chat/${result.chatID}${result.messageID ? `#msg-${result.messageID}` : ''}`),
      }))
      setActions(_actions)
    }
    handleUpdate()
    chatManager.on('update', handleUpdate)
    return () => {
      chatManager.off('update', handleUpdate)
    }
  }, [locale, chatManager])

  const props = useMemo(
    () => ({
      highlightQuery: true,
      shortcut: ['/'],
      // overlayColor: '#000000',
      searchPlaceholder: formatMessage({ defaultMessage: 'Search your chats' }),
      searchIcon: <i className="fa fa-search" />,
      actions,
    }),
    [locale, actions],
  )

  return props
}
