import { Button, CopyButton as MantineCopyButton } from '@mantine/core'
import { FormattedMessage } from 'react-intl'

export function CopyButton(props: { value: string }) {
  return (
    <MantineCopyButton value={props.value}>
      {({ copy, copied }) => (
        <Button variant="subtle" size="sm" compact onClick={copy} style={{ marginLeft: '1rem' }}>
          <i className="fa fa-clipboard" />
          {copied ? (
            <FormattedMessage
              defaultMessage="Copied"
              description="Label for copy-to-clipboard button after a successful copy"
            />
          ) : (
            <FormattedMessage defaultMessage="Copy" description="Label for copy-to-clipboard button" />
          )}
        </Button>
      )}
    </MantineCopyButton>
  )
}
