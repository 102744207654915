import { getLocale } from '../components/intl'
import { OptionGroup } from '../core/options/option-group'
import { ttsPlugins } from '../core/plugins/metadata'

const ttsPluginMetadata = ttsPlugins.map((p) => new p().describe())

export const ttsServiceOptions: OptionGroup = {
  id: 'tts',
  options: [
    {
      id: 'autoplay',
      displayOnSettingsScreen: 'speech',
      defaultValue: false,
      displayAsSeparateSection: true,
      displayInQuickSettings: {
        displayByDefault: true,
        label: 'tts--autoplay',
      },
      renderProps: {
        type: 'checkbox',
        label: 'autoplay', // will be translated, see system-messages
      },
    },
    {
      id: 'service',
      displayOnSettingsScreen: 'speech',
      defaultValue: () => (getLocale() === 'en' ? 'elevenlabs' : 'web-speech'),
      displayAsSeparateSection: true,
      renderProps: {
        type: 'select',
        label: 'service',
        options: ttsPluginMetadata.map((p) => ({
          label: p.name,
          value: p.id,
        })),
      },
    },
  ],
}
