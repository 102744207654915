/* eslint-disable no-param-reassign */
import styled from '@emotion/styled'
import { Title, useMantineTheme } from '@mantine/core'
import randomInteger from 'random-int'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TagCloud } from 'react-tagcloud'
import env from '../../env'
import { useIntlContext } from '../intl'
import { useMessage } from '../message'
import { Page } from '../page'

const Container = styled.div`
  flex-grow: 1;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  font-family: 'Work Sans', sans-serif;
  text-align: center;
  line-height: 1.7;
  gap: 1rem;
`
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex
  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }
  return array
}

// const customRenderer = (tag, size, color) => (
//   <span
//     key={tag.value}
//     style={{
//       animation: 'blinker 3s linear infinite',
//       animationDelay: `${Math.random() * 2}s`,
//       fontSize: `${size / 2}em`,
//       border: `2px solid ${color}`,
//       margin: '3px',
//       padding: '3px',
//       display: 'inline-block',
//       color: 'white',
//     }}
//   >
//     {tag.value}
//   </span>
// )

// START workaround for https://github.com/mantinedev/mantine/issues/4417:
const eventListerOptions = {
  passive: true,
}
export function useWindowEvent<K extends string>(
  type: K,
  listener: K extends keyof WindowEventMap
    ? (this: Window, ev: WindowEventMap[K]) => void
    : (this: Window, ev: CustomEvent) => void,
  options?: boolean | AddEventListenerOptions,
) {
  useEffect(() => {
    // @ts-ignore
    window.addEventListener(type, listener, options)
    // @ts-ignore
    return () => window.removeEventListener(type, listener, options)
  }, [type, listener])
}
export function useViewportSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const setSize = useCallback(() => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])

  useWindowEvent('resize', setSize, eventListerOptions)
  useWindowEvent('orientationchange', setSize, eventListerOptions)
  useEffect(setSize, [])

  return windowSize
}
// END workaround

type Dims = {
  amount: number
  hasMaxHeight: boolean
  hasMedHeight: boolean
  hasMinHeight: boolean
  hasMaxWidth: boolean
  hasMedWidth: boolean
  hasMinWidth: boolean
  imgWidth: string
  height: number
  maxSize: number
  minSize: number
  textWidth: string
  width: number
}

export const getDims = ({ height, width }: { height: number; width: number }): Dims => {
  const hasLargeHeight = height > 1300
  const hasMaxHeight = height > 1000
  const hasMedHeight = height > 750
  const hasMinHeight = height <= 500
  const hasMaxWidth = width > 800
  const hasMedWidth = width > 600
  const hasMinWidth = width <= 400
  const amount = hasLargeHeight ? 7 : hasMaxHeight ? 6 : hasMedHeight ? 4 : 3
  const imgWidth = !hasMaxHeight && height > width ? '30vw' : '20vw'
  const minSize = hasMaxHeight && hasMaxWidth ? 16 : hasMedHeight && hasMedWidth ? 14 : 12
  const maxSize = hasMaxHeight && hasMaxWidth ? 30 : hasMedHeight && hasMedWidth ? 26 : 20
  const textWidth = hasMedWidth && hasMedHeight ? '50vw' : !(hasMinWidth || hasMinHeight) ? '70vw' : '90vw'
  return {
    amount,
    hasMaxHeight,
    hasMedHeight,
    hasMinHeight,
    hasMaxWidth,
    hasMedWidth,
    hasMinWidth,
    height,
    imgWidth,
    maxSize,
    minSize,
    textWidth,
    width,
  }
}

const customRenderer = (tag, size: string | number, color) => {
  const { className, style, ...props } = tag.props || {}
  const fontSize = `${size}px`
  const key = tag.key || tag.value
  const tagStyle = { ...styles, color, fontSize, ...style }

  let tagClassName = 'tag-cloud-tag'
  if (className) {
    tagClassName += ` ${className}`
  }

  return (
    <span data-id={`landing-question-${tag.q}`} className={tagClassName} style={tagStyle} key={key} {...props}>
      {tag.value}
    </span>
  )
}

const styles = {
  margin: '0 5px',
  verticalAlign: 'middle',
  display: 'inline-block',
}

export default function LandingPage() {
  const { onSubmit } = useMessage()
  const { questions } = useIntlContext()
  const { height, width } = useViewportSize()
  const theme = useMantineTheme()
  const [dims, setDims] = useState<Dims>(getDims({ height, width }))
  const { colorScheme: scheme } = theme

  useEffect(() => {
    const _dims = getDims({ height, width })
    setDims(_dims)
  }, [height, width])

  const colorOptions = useMemo(
    () => ({
      // can be bright, light or dark
      luminosity: scheme === 'dark' ? 'light' : 'dark',
      hue: scheme === 'dark' ? theme.white : env.REACT_APP_COLOR,
    }),
    [scheme],
  )

  const shuffledData = useMemo(() => {
    if (!questions) return
    const data = Object.keys(questions).map((k) => ({
      value: questions[k],
      count: randomInteger(dims.minSize, dims.maxSize),
      q: k,
    }))
    return shuffle(data)
  }, [dims, questions])

  // @ts-ignore
  return useMemo(
    () => (
      <Page id={'landing'}>
        <Container>
          {!dims.hasMinHeight && (
            <div
              style={{
                alignSelf: 'top',
                width: dims.textWidth,
              }}
            >
              <img
                src={`/logo-${scheme}.svg`}
                style={{
                  width: dims.imgWidth,
                  height: dims.imgWidth,
                  color: scheme === 'light' ? 'black' : 'white',
                }}
                alt="logo"
              />
            </div>
          )}
          <Title
            order={dims.hasMaxWidth && dims.hasMaxHeight ? 1 : dims.hasMedWidth && !dims.hasMinHeight ? 2 : 3}
            style={{
              width: dims.textWidth,
            }}
          >
            <FormattedMessage
              defaultMessage={'Hello, how can I help you today?'}
              description="A friendly message that appears at the start of new chat sessions"
            />
          </Title>
          {shuffledData && (
            <TagCloud
              minSize={dims.minSize}
              maxSize={dims.maxSize}
              tags={shuffledData.slice(0, dims.amount)}
              colorOptions={colorOptions}
              renderer={customRenderer}
              style={{
                cursor: 'pointer',
                width: dims.textWidth,
              }}
              onClick={(tag) => onSubmit(tag.value as string)}
            />
          )}
        </Container>
      </Page>
    ),
    [colorOptions, dims, questions, shuffledData, scheme, onSubmit],
  )
}
