import AudioRecorder from 'audio-recorder-polyfill'
import hark from 'hark'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import languages from '../../lang/languages.json'

let mediaRecorder: MediaRecorder
const useWhisper = () => {
  const [transcribing, setTranscribing] = useState(false)
  const [transcript, setTranscript] = useState('')
  const { locale } = useIntl()
  const [language] = languages[locale]

  const sendRecording = async (audioData: Blob): Promise<any> => {
    // first convert the audio data to base64
    const reader = new FileReader()
    reader.readAsDataURL(audioData)
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        setTranscribing(true)
        // Send base64 string data backend service
        fetch('/chatapi/proxies/openai/v1/audio/transcriptions', {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ audio: reader.result, language, locale }),
        })
          .then((res) => res.json())
          .then((data) => resolve(data.text.trim()))
          .catch((err) => {
            reject(err)
          })
          .finally(() => {
            setTranscribing(false)
          })
      }
    })
  }
  const startRecording = async () => {
    if (navigator.mediaDevices.getUserMedia) {
      let timer: string | number | NodeJS.Timeout | undefined
      console.log('Starting to record')
      // Get audio stream
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      })
      // Generate the media recorder with stream from media devices
      // Starting position is paused recording
      mediaRecorder = new (navigator.userAgent.includes('Safari') ? AudioRecorder : MediaRecorder)(stream)
      // Also pass the stream to hark to create speaking events
      const speech = hark(stream, { interval: 20 })
      // Start the recording when hark recognizes someone is speakign in the mic
      speech.on('speaking', function () {
        console.log('Speaking!')
        if (timer) clearTimeout(timer)
        mediaRecorder.start()
      })
      // When hark recognizes the speaking has stopped we can stop recording
      // The stop action will generate ondataavailable() to be triggered
      speech.on('stopped_speaking', function () {
        console.log('Not Speaking')
        timer = setTimeout(() => {
          if (mediaRecorder.state === 'recording') mediaRecorder.stop()
        }, 1000)
      })
      mediaRecorder.addEventListener('dataavailable', (e) => {
        sendRecording(e.data)
          .then((newMessage) => {
            setTranscript(`${newMessage}`)
          })
          .catch((err) => {
            throw err
          })
      })
    } else {
      console.log('recording not supported')
    }
  }

  const stopRecording = () => {
    if (!mediaRecorder) return
    if (mediaRecorder.state === 'recording') mediaRecorder.stop()
    mediaRecorder.stream.getTracks().forEach((track) => track.stop())
  }
  return {
    transcribing,
    transcript,
    setTranscript,
    startRecording,
    stopRecording,
  }
}
export default useWhisper
