import { registeredPlugins } from '../../plugins'
import TTSPlugin from '../tts/tts-plugin'
import type { PluginDescription } from './plugin-description'

export const pluginMetadata: Array<PluginDescription> = registeredPlugins.map((p) => new p().describe())

export const ttsPlugins = registeredPlugins.filter((p) => {
  const instance = new p()
  return instance instanceof TTSPlugin
})

export function getPluginByName(name: string) {
  return registeredPlugins.find((p) => new p().describe().name === name)
}
