import styled from '@emotion/styled'
import {
  Button,
  NumberInput,
  PasswordInput,
  Select,
  Slider,
  Switch,
  Tabs,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { Context, useAppContext } from '../../core/context'
import { Option } from '../../core/options/option'
import { useOption } from '../../core/options/use-option'
import { pluginMetadata } from '../../core/plugins/metadata'
import { globalOptions } from '../../global-options'
import systemMessages from '../../lang/system-messages'
import { useAppSelector } from '../../store'
import { selectSettingsOption } from '../../store/settings-ui'
import { useMessage } from '../message'
import SettingsOption from './option'

const Settings = styled.div`
  font-family: 'Work Sans', sans-serif;
  // color: white;

  section {
    margin-bottom: 0.618rem;
    padding: 0.618rem;

    section {
      padding-left: 0;
      padding-right: 0;
    }

    h3 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.7;
      margin-top: 0.8rem;
      font-size: 1rem;
    }

    a {
      // color: white;
      text-decoration: underline;
    }

    code {
      font-family: 'Fira Code', monospace;
    }

    .mantine-NumberInput-root,
    .slider-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }
  }

  .focused {
    border: thin solid rgba(255, 255, 255, 0.1);
    border-radius: 0.25rem;
    animation: flash 3s;
  }

  @keyframes flash {
    0% {
      border-color: rgba(255, 0, 0, 0);
    }
    50% {
      border-color: rgba(255, 0, 0, 1);
    }
    100% {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
`

const OptionWrapper = styled.div`
  & {
    margin-top: 1rem;
  }

  * {
    font-family: 'Work Sans', sans-serif;
    // color: white;
    font-size: 1rem;
  }
`

export function PluginOptionWidget(props: {
  pluginID: string
  option: Option
  chatID?: string | null | undefined
  context: Context
}) {
  const requestedOption = useAppSelector(selectSettingsOption)
  const { option } = props
  const [_value, setValue, renderProps] = useOption(props.pluginID, option.id, props.chatID || undefined)
  const { formatMessage } = useIntl()

  const value = _value ?? (typeof option.defaultValue === 'function' ? option.defaultValue() : option.defaultValue)

  if (option.defaultValue && (typeof value === 'undefined' || value === null)) {
    console.warn(`expected option value for ${props.pluginID}.${option.id}, got:`, _value)
  }

  if (renderProps.hidden) {
    return null
  }
  const { description, disabled, placeholder, min, max, options, type, step } = renderProps
  const _label = formatMessage(systemMessages[option.id])
  const _placeholder = placeholder ? formatMessage(systemMessages[placeholder]) : ''
  let component: any

  switch (type) {
    case 'textarea':
      component = (
        <Textarea
          label={!option.displayAsSeparateSection ? _label : null}
          placeholder={_placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
          minRows={5}
        />
      )
      break
    case 'select': {
      const _options = options!.map((o) => ({
        ...o,
        label: systemMessages[o.value] ? formatMessage(systemMessages[o.value]) : o.label,
      }))
      component = (
        <Select
          label={!option.displayAsSeparateSection ? _label : null}
          placeholder={_placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={(value) => setValue(value)}
          data={_options ?? []}
        />
      )
      break
    }
    case 'slider':
      component = (
        <div className="slider-wrapper">
          {!option.displayAsSeparateSection && (
            <Text size="sm" weight={500}>
              {_label}:
            </Text>
          )}
          <Slider
            label={value.toString()}
            disabled={disabled}
            value={value}
            onChange={(v) => setValue(v)}
            min={min}
            max={max}
            step={step}
            style={{
              minWidth: '10rem',
              flexGrow: 1,
            }}
          />
        </div>
      )
      break
    case 'number':
      component = (
        <NumberInput
          label={!option.displayAsSeparateSection ? `${_label}:` : null}
          disabled={disabled}
          value={value ?? undefined}
          onChange={(v) => setValue(v)}
          min={min}
          max={max}
          step={step}
        />
      )
      break
    case 'checkbox':
      component = (
        <Switch
          label={!option.displayAsSeparateSection ? _label : null}
          disabled={disabled}
          checked={value}
          onChange={(e) => setValue(e.target.checked)}
        />
      )
      break
    case 'password':
      component = (
        <PasswordInput
          label={!option.displayAsSeparateSection ? _label : null}
          placeholder={_placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )
      break
    case 'text':
    default:
      component = (
        <TextInput
          label={!option.displayAsSeparateSection ? _label : null}
          placeholder={_placeholder}
          disabled={disabled}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      )
      break
  }

  const focused = !!requestedOption && option.id === requestedOption

  const elem = (
    <OptionWrapper className={focused && !option.displayAsSeparateSection ? 'focused' : ''}>
      {component}
      {typeof description?.props === 'undefined' && <p style={{ marginBottom: '0.7rem' }}>{description}</p>}
      {typeof description?.props !== 'undefined' && description}
    </OptionWrapper>
  )

  if (option.displayAsSeparateSection) {
    return (
      <SettingsOption heading={_label} focused={focused}>
        {elem}
        {option.resettable && (
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              marginTop: '1rem',
            }}
          >
            <Button size="xs" compact variant="filled" onClick={() => setValue(option.defaultValue)}>
              <FormattedMessage defaultMessage="Reset to default" />
            </Button>
          </div>
        )}
      </SettingsOption>
    )
  }

  return elem
}

export default function SettingsTab(props: { name: string; children?: any }) {
  const context = useAppContext()
  const { chatManager } = useAppContext()
  const { id: chatID } = useMessage()
  const { formatMessage } = useIntl()

  const optionSets = [...globalOptions, ...pluginMetadata]
    .map((metadata) => ({
      id: metadata.id,
      name: metadata.name,
      description: metadata.description,
      options: metadata.options
        .filter((o) => o.displayOnSettingsScreen === props.name)
        .map((o) => ({ ...o, name: formatMessage(systemMessages[o.id]) })),
      resettable:
        metadata.options.filter(
          (o) => o.displayOnSettingsScreen === props.name && o.resettable && !o.displayAsSeparateSection,
        ).length > 0,
      collapsed:
        metadata.options.filter((o) => o.displayOnSettingsScreen === props.name && o.displayAsSeparateSection).length >
        0,
      hidden: typeof metadata.hidden === 'function' ? metadata.hidden(chatManager.options) : metadata.hidden,
    }))
    .filter(({ options, hidden }) => options.length && !hidden)
    .map((metadata) => ({
      ...metadata,
      name: formatMessage(systemMessages[metadata.id]),
    }))

  return (
    <Tabs.Panel value={props.name}>
      <Settings>
        {props.children}
        {optionSets.map(({ name, id, description, options, resettable, collapsed }) => (
          <>
            <SettingsOption heading={name} description={description} collapsed={collapsed} key={`setting-${id}`}>
              {options.map((o) => (
                <PluginOptionWidget
                  pluginID={id}
                  option={o}
                  chatID={chatID}
                  context={context}
                  key={`setting-option-${id}.${o.id}`}
                />
              ))}
              {resettable && (
                <div
                  key={`${id}.reset`}
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    key={`${id}.button`}
                    size="xs"
                    compact
                    variant="filled"
                    onClick={() => chatManager.resetPluginOptions(id, chatID)}
                  >
                    <FormattedMessage defaultMessage="Reset to default" />
                  </Button>
                </div>
              )}
            </SettingsOption>
          </>
        ))}
      </Settings>
    </Tabs.Panel>
  )
}
