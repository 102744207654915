import { OptionGroup } from '../core/options/option-group'

export const autoScrollOptions: OptionGroup = {
  id: 'auto-scroll',
  name: 'Autoscroll',
  options: [
    {
      id: 'auto-scroll-when-opening-chat',
      defaultValue: true,
      displayOnSettingsScreen: 'ui',
      displayAsSeparateSection: false,
      renderProps: {
        type: 'checkbox',
      },
    },
    {
      id: 'auto-scroll-while-generating',
      defaultValue: true,
      displayOnSettingsScreen: 'ui',
      displayAsSeparateSection: false,
      renderProps: {
        type: 'checkbox',
      },
    },
  ],
}

export const inputOptions: OptionGroup = {
  id: 'input',
  name: 'Message Input',
  options: [
    {
      id: 'submit-on-enter',
      defaultValue: true,
      displayOnSettingsScreen: 'ui',
      displayAsSeparateSection: false,
      displayInQuickSettings: {
        displayByDefault: true,
        label: 'input--submit-on-enter',
      },
      renderProps: {
        type: 'checkbox',
      },
    },
  ],
}
