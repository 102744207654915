import styled from '@emotion/styled'
import { Button, MantineTheme } from '@mantine/core'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAppContext } from '../core/context'
import { Option } from '../core/options/option'
import { useOption } from '../core/options/use-option'
import systemMessages from '../lang/system-messages'
import { getMainColor } from '../theme'
import { useMessage } from './message'

const Container = styled.div<{ theme?: MantineTheme | undefined }>(
  ({ theme }) => `
  margin: 0.5rem -0.5rem;

  display: flex;
  flex-wrap: wrap;
  text-align: left;

  justify-content: center;

  @media (min-width: 40em) {
    justify-content: flex-end;
  }

  .mantine-Button-root {
    font-size: 0.7rem;
    color: ${theme.colors.gray[5]};
    &.selected {
      color: ${getMainColor(theme)};
    }
  }
`,
)

export function QuickSettingsButton(props: { groupID: string; option: Option }) {
  const context = useAppContext()
  const { id } = useMessage()
  const { formatMessage } = useIntl()

  const [value, setOptionValue] = useOption(props.groupID, props.option.id, id || undefined)
  const isBool = typeof value === 'boolean'

  const onClick = useCallback(() => {
    setOptionValue(!value)
  }, [value])

  const labelBuilder = props.option.displayInQuickSettings?.label
  let _label = props.option.id

  if (labelBuilder) {
    _label = typeof labelBuilder === 'string' ? labelBuilder : labelBuilder(value, context.chatManager.options, context)
  }
  const label = formatMessage(systemMessages[_label])
  return (
    <Button
      className={isBool && value ? 'selected' : ''}
      variant="subtle"
      size="xs"
      compact
      onClick={onClick}
      data-id={`quick-setting-${props.option.id}`}
    >
      <span>{label}</span>
    </Button>
  )
}

export default function QuickSettings() {
  const { chatManager } = useAppContext()
  const options = chatManager.getQuickSettings()

  if (!options.length) {
    return <div style={{ height: '1rem' }} />
  }

  return (
    <Container>
      {options.map((o) => (
        <QuickSettingsButton groupID={o.groupID} option={o.option} key={`${o.groupID}.${o.option.id}`} />
      ))}
    </Container>
  )
}
