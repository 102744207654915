import React from 'react'

interface ErrorState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<any, ErrorState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    // if (this.state.hasError) {
    //   return (
    //     <>
    //       <ErrorModal onClose={() => this.setState({ hasError: false })} />
    //       {this.props.children}
    //     </>
    //   )
    // }

    return this.props.children
  }
}
