import styled from '@emotion/styled'
import { Accordion, Anchor, Button, List, Loader, Text, Textarea, useMantineTheme } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { uuidv4 } from 'lib0/random'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { Message, UserSubmittedMessage } from '../core/chat/types'
import { UseChatResult, useChat } from '../core/chat/use-chat'
import { useAppContext } from '../core/context'
import { resetAudioContext } from '../core/tts/audio-file-player'
import { share as shareChat } from '../core/utils'
import { useAppDispatch, useAppSelector } from '../store'
import { selectMessage, setMessage } from '../store/message'
import { selectSettingsTab } from '../store/settings-ui'
import { CopyButton } from './copy-button'
import ErrorModal from './error-modal'
import { Markdown } from './markdown'
import { usePage } from './page'
import { TTSButton } from './tts-button'

// hide for everyone but screen readers
const SROnly = styled.span`
  position: fixed;
  left: -9999px;
  top: -9999px;
`

const Container = styled.div`
  &.by-user {
    // background: #22232b;
  }

  &.by-assistant {
    // background: #292933;
  }

  &.by-assistant + &.by-assistant,
  &.by-user + &.by-user {
    // border-top: 0.2rem dotted rgba(0, 0, 0, 0.1);
    border-top: 0.2rem dotted;
  }

  &.by-assistant {
    // border-bottom: 0.2rem solid rgba(0, 0, 0, 0.1);
    // border-bottom: 0.2rem solid;
  }

  position: relative;
  padding: 1.618rem;

  @media (max-width: 40em) {
    padding: 1rem;
  }

  .inner {
    margin: auto;
  }

  .content {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    margin-top: 0rem;
    max-width: 100%;

    * {
      // color: white;
    }

    p,
    ol,
    ul,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    img,
    blockquote,
    & > pre {
      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      display: block;
      max-width: 50rem;

      @media (max-width: 50rem) {
        max-width: 100%;
      }
    }

    ol {
      counter-reset: list-item;

      li {
        counter-increment: list-item;
      }
    }

    em,
    i {
      font-style: italic;
    }

    code {
      &,
      * {
        font-family: 'Fira Code', monospace !important;
      }
      vertical-align: bottom;
    }

    /* Tables */
    table {
      margin-top: 1.618rem;
      border-spacing: 0px;
      border-collapse: collapse;
      // border: thin solid rgba(255, 255, 255, 0.1);
      border: thin solid;
      width: 100%;
      max-width: 55rem;
      margin-left: auto;
      margin-right: auto;
    }
    td + td,
    th + th {
      // border-left: thin solid rgba(255, 255, 255, 0.1);
      border-left: thin solid;
    }
    tr {
      // border-top: thin solid rgba(255, 255, 255, 0.1);
      border-top: thin solid;
    }
    table td,
    table th {
      padding: 0.618rem 1rem;
    }
    th {
      font-weight: 600;
      // background: rgba(255, 255, 255, 0.1);
    }
  }

  .metadata {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
    opacity: 0.6;
    max-width: 50rem;
    margin-bottom: 0rem;
    margin-right: -0.5rem;
    margin-left: auto;
    margin-right: auto;

    span + span {
      margin-left: 1em;
    }

    .fa {
      font-size: 85%;
    }

    .fa + span {
      margin-left: 0.2em;

      @media (max-width: 40em) {
        display: none;
      }
    }

    .mantine-Button-root {
      // color: #ccc;
      font-size: 0.8rem;
      font-weight: 400;

      .mantine-Button-label {
        display: flex;
        align-items: center;
      }
    }
  }

  .fa {
    margin-right: 0.5em;
    font-size: 85%;
  }

  .buttons {
    text-align: right;
  }

  strong {
    font-weight: bold;
  }
`

const EndOfChatMarker = styled.div`
  position: absolute;
  bottom: calc(-1.618rem - 0.5rem);
  left: 50%;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: -0.25rem;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
`

const Editor = styled.div`
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;

  .mantine-Button-root {
    margin-top: 1rem;
  }
`

function InlineLoader() {
  return (
    <Loader
      variant="dots"
      size="xs"
      style={{
        marginLeft: '1rem',
        position: 'relative',
        top: '-0.2rem',
      }}
    />
  )
}

export interface MessageContext {
  currentChat: UseChatResult
  editMessage: (message: UserSubmittedMessage, content: string, bustCache?: boolean) => void
  generating: boolean
  id: string | undefined | null
  matchInputLang: boolean
  nextID: string
  onNewMessage: (message: string) => string | undefined
  onSubmit: (message?: string) => void
  regenerateMessage: (message: Message) => void
  setMatchInputLang: CallableFunction
  setNextID: CallableFunction
}

export const useMessage = () => {
  const message = useAppSelector(selectMessage)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { chatManager } = useAppContext()
  const { isShare } = usePage()
  const { id: _id } = useParams()
  const [nextID, setNextID] = useState<string>(uuidv4() as string)
  const id: string = _id ?? nextID
  const currentChat = useChat(chatManager, id, isShare)

  const [matchInputLang, setMatchInputLang] = useLocalStorage<boolean>({
    key: 'same-lang',
    serialize: (value) => (value ? 'true' : 'false'),
    deserialize: (localStorageValue) => (localStorageValue === 'true' ? true : false),
    defaultValue: false,
  })

  const generating =
    currentChat?.messagesToDisplay?.length > 0
      ? !currentChat.messagesToDisplay[currentChat.messagesToDisplay.length - 1].done
      : false

  const onNewMessage = useCallback(
    (message?: string) => {
      resetAudioContext()

      if (isShare) {
        return
      }

      if (!message?.trim().length) {
        return
      }

      if (id === nextID) {
        setNextID(uuidv4() as string)

        const autoPlay = chatManager.options.getOption<boolean>('tts', 'autoplay')

        if (autoPlay) {
          const ttsService = chatManager.options.getOption<string>('tts', 'service')
          if (ttsService === 'web-speech') {
            const utterance = new SpeechSynthesisUtterance('Generating')
            utterance.rate = 0.85
            // utterance.volume = 0
            speechSynthesis.speak(utterance)
          }
        }
      }

      chatManager.sendMessage({
        chatID: id,
        content: message.trim(),
        parentID: currentChat.leaf?.id,
        matchInputLang,
      })

      return id
    },
    [id, currentChat.leaf, isShare, matchInputLang],
  )

  const onSubmit = useCallback(
    (inMessage?: string) => {
      let msg = message
      if (inMessage) {
        msg = inMessage
        dispatch(setMessage(msg))
      }
      const id = onNewMessage(msg)

      if (id) {
        if (!window.location.pathname.includes(id)) {
          navigate(`/chat/${id}`)
        }
        dispatch(setMessage(''))
      }
    },
    [message],
  )

  const regenerateMessage = useCallback(
    (message: Message) => {
      resetAudioContext()

      if (isShare) {
        return
      }

      chatManager.regenerate(message)
    },
    [isShare],
  )

  const editMessage = useCallback(
    (message: UserSubmittedMessage, content: string, bustCache = false) => {
      resetAudioContext()

      if (isShare) {
        return
      }

      if (!content?.trim().length) {
        return
      }
      const msg = {
        content: content.trim(),
        parentID: message.parentID,
        matchInputLang: message.matchInputLang,
      }
      if (id && chatManager.has(id)) {
        chatManager.sendMessage({ ...msg, chatID: id }, bustCache)
      } else {
        chatManager.sendMessage({ ...msg, chatID: chatManager.createChat() }, bustCache)
      }
    },
    [id, isShare],
  )

  const context = useMemo<MessageContext>(
    () => ({
      currentChat,
      editMessage,
      generating,
      id,
      matchInputLang,
      nextID,
      onNewMessage,
      onSubmit,
      regenerateMessage,
      setMatchInputLang,
      setNextID,
    }),
    [generating, currentChat, id, matchInputLang],
  )

  return context
}

export default function MessageComponent({
  last,
  message,
  share,
}: {
  last: boolean
  message: Message
  share?: boolean
}) {
  const theme = useMantineTheme()
  const { chatManager, error, setError } = useAppContext()
  const { isShare } = usePage()
  const { editMessage, regenerateMessage } = useMessage()
  const [editing, setEditing] = useState(false)
  const [content, setContent] = useState('')
  const { formatMessage } = useIntl()
  const tab = useAppSelector(selectSettingsTab)

  const getRoleName = (role: string, _share = false) => {
    switch (role) {
      case 'user':
        if (_share) {
          return formatMessage({
            id: 'role-user-formal',
            defaultMessage: 'User',
            description:
              'Label that is shown above messages written by the user (as opposed to the AI) for publicly shared conversation (third person, formal).',
          })
        } else {
          return formatMessage({
            id: 'role-user',
            defaultMessage: 'You',
            description:
              "Label that is shown above messages written by the user (as opposed to the AI) in the user's own chat sessions (first person).",
          })
        }
      case 'assistant':
        return formatMessage({
          id: 'role-chatgpt',
          defaultMessage: 'AI assistant',
          description: 'Label that is shown above messages written by the AI (as opposed to the user)',
        })
      case 'system':
        return formatMessage({
          id: 'role-system',
          defaultMessage: 'System',
          description:
            'Label that is shown above messages inserted into the conversation automatically by the system (as opposed to either the user or AI)',
        })
      default:
        return role
    }
  }

  const sources = JSON.parse(message.sources || '[]')

  const elem = useMemo(() => {
    if (message.role === 'system') {
      return null
    }

    return (
      <Container className={`message by-${message.role}`}>
        <div className="inner">
          <div className="metadata">
            <span>
              <strong>
                {getRoleName(message.role, share)}
                {message.model === 'gpt-4' && ' (GPT 4)'}
                <SROnly>:</SROnly>
              </strong>
              {message.role === 'assistant' && last && !message.done && <InlineLoader />}
            </span>
            <TTSButton
              id={message.id}
              selector={`.content-${message.id}`}
              complete={!!message.done}
              autoplay={last && chatManager.lastReplyID === message.id}
            />
            <div style={{ flexGrow: 1 }} />
            {/* {message.role === 'user' && env.REACT_APP_DOCS_MULTILANG && locale !== env.REACT_APP_DOCS_LANG_CODE && (
              <ActionIcon
                className={`selectable ${message.matchInputLang ? 'selected' : undefined}`}
                title={formatMessage(
                  {
                    defaultMessage:
                      'If checked, the AI will try to find documentation in the chosen language (<p>English</p>).',
                  },
                  {
                    p: () => languages[locale][1],
                  },
                )}
                variant="subtle"
                size="lg"
                onClick={() => {
                  void editMessage({ ...message, matchInputLang: !message.matchInputLang }, message.content, true)
                }}
              >
                <i className="fa-solid fa-language" />
              </ActionIcon>
            )} */}
            <CopyButton value={message.content} />
            {typeof navigator.share !== 'undefined' && share && (
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              <Button variant="subtle" size="sm" compact onClick={() => shareChat(message.content)}>
                <i className="fa fa-share" />
                <span>
                  <FormattedMessage
                    defaultMessage="Share"
                    description="Label for a button which shares the text of a chat message using the user device's share functionality"
                  />
                </span>
              </Button>
            )}
            {!isShare && message.role === 'user' && (
              <Button
                variant="subtle"
                size="sm"
                compact
                onClick={() => {
                  setContent(message.content)
                  setEditing((v) => !v)
                }}
                style={{ marginLeft: '1rem' }}
              >
                <i className="fa fa-edit" />
                <span>
                  {editing ? (
                    <FormattedMessage
                      defaultMessage="Cancel"
                      description="Label for a button that appears when the user is editing something, to cancel without saving changes"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Edit"
                      description="Label for the button the user can click to edit the text of one of their messages"
                    />
                  )}
                </span>
              </Button>
            )}
            {!isShare && message.role === 'assistant' && (
              <Button
                data-id="message-regenerate"
                variant="subtle"
                size="sm"
                compact
                onClick={() => void regenerateMessage(message)}
                style={{ marginLeft: '1rem' }}
              >
                <i className="fa fa-refresh" />
                <span>
                  <FormattedMessage
                    defaultMessage="Regenerate"
                    description="Label for the button used to ask the AI to regenerate one of its messages. Since message generations are stochastic, the resulting message will be different."
                  />
                </span>
              </Button>
            )}
          </div>
          {error && <ErrorModal onClose={() => setError(false)} />}
          {!editing && !chatManager.error && (
            <Markdown content={message.content} className={`content content-${message.id}`} />
          )}
          {!editing && !chatManager.error && message.role === 'assistant' && sources.length > 0 && (
            <Accordion
              defaultValue="links"
              className={`content content-${message.id}`}
              style={{
                paddingTop: '1rem',
              }}
            >
              <Accordion.Item value="links">
                <Accordion.Control>
                  <Text size="sm">
                    <FormattedMessage defaultMessage="Links:" />
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <List size="sm" listStyleType="disc">
                    {sources.map(([s1, s2]) => (
                      <List.Item key={s1}>
                        <Anchor href={s2} target="_blank">
                          {s1}
                        </Anchor>
                      </List.Item>
                    ))}
                  </List>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
          {editing && (
            <Editor>
              <Textarea value={content} onChange={(e) => setContent(e.currentTarget.value)} autosize={true} />
              <Button variant="filled" onClick={() => void editMessage(message, content)}>
                <FormattedMessage
                  defaultMessage="Save changes"
                  description="Label for a button that appears when the user is editing something, to save the changes"
                />
              </Button>
              <Button variant="subtle" onClick={() => setEditing(false)}>
                <FormattedMessage
                  defaultMessage="Cancel"
                  description="Label for a button that appears when the user is editing something, to cancel without saving changes"
                />
              </Button>
            </Editor>
          )}
        </div>
        {last && <EndOfChatMarker />}
      </Container>
    )
  }, [error, last, share, editing, content, message, message.content, tab, theme])

  return elem
}
