/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '.'

type State = {
  modal: string
  ui: Record<string, any>
}

const initialState: State = {
  modal: '',
  ui: {},
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openLoginModal(state) {
      state.modal = 'login'
    },
    openSignupModal(state) {
      state.modal = 'signup'
    },
    closeModals(state) {
      state.modal = ''
    },
  },
})

export const { openLoginModal, openSignupModal, closeModals } = uiSlice.actions

export const selectModal = (state: RootState) => state.ui.modal

export default uiSlice.reducer
