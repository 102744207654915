import EventEmitter from 'events'
import SSE from '../utils/sse'
import { OpenAIMessage } from './types'

export function createApiChatCompletion(
  messages: OpenAIMessage[],
  matchInputLang: boolean,
  bustCache: boolean,
): { emitter: EventEmitter; cancel: () => void } {
  const endpoint = '/chatapi/chat'
  const emitter = new EventEmitter()
  let contents = ''

  const eventSource = new SSE(endpoint, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    payload: JSON.stringify({
      messages,
      matchInputLang,
      bustCache,
    }),
  })

  eventSource.addEventListener('error', (event: any) => {
    if (!contents) {
      let error = event.data
      try {
        error = JSON.parse(error as string).error.message
      } catch (e) {}
      emitter.emit('error', error)
    }
  })

  eventSource.addEventListener('message', (event: any) => {
    let { data } = event
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (!isNaN(data) && data !== '' && contents !== '') {
      data = ` ${event.data}`
    }
    contents += data
    // when sources were provided we can render them last under the answer
    if (event.sources) emitter.emit('sources', event.sources)
    else emitter.emit('data', contents)
  })

  eventSource.addEventListener('done', () => {
    emitter.emit('done')
  })

  eventSource.stream()

  return {
    emitter,
    cancel: () => eventSource.close(),
  }
}

export async function createApiChatTitle(messages: OpenAIMessage[]): Promise<string> {
  const endpoint = '/chatapi/title'

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      Accept: 'text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      messages,
    }),
  })

  const title = await response.text()

  return title
}
