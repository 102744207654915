/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Chat, OpenAIMessage } from '../chat/types'
import { OptionsManager } from '../options'

export interface PluginContext {
  getOptions(): any
  getCurrentChat(): Chat
  createChatTitle(messages: OpenAIMessage[]): Promise<string>
  setChatTitle(title: string): Promise<void>
}

export function createBasicPluginContext(
  pluginID: string,
  pluginOptions: OptionsManager,
  chatID?: string | null,
  chat?: Chat | null,
) {
  return {
    getOptions: (_pluginID = pluginID) => pluginOptions.getAllOptions(_pluginID, chatID),
    getCurrentChat: () => chat,
    createChatTitle: async () => '',
    setChatTitle: async (_title: string) => {},
  } as PluginContext
}
