import { useLocalStorage } from '@mantine/hooks'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { IntlProvider } from 'react-intl'
import lang from '../lang/index'
import languages from '../lang/languages.json'

export const langCodes = Object.keys(lang)

if (window.location.search.includes('locale=')) {
  // eslint-disable-next-line prefer-destructuring
  const locale = window.location.search.split('locale=')[1].split('&')[0]
  if (langCodes.includes(locale)) {
    localStorage.setItem('locale', JSON.stringify(locale))
  }
}

export const getLocale = (): string => {
  const savedLocale = localStorage.getItem('locale')
  const navLocale = navigator.language.substring(0, 2)
  return (
    (savedLocale && (savedLocale.startsWith('"') ? JSON.parse(savedLocale) : savedLocale)) ||
    langCodes[navLocale && navLocale] ||
    'en'
  )
}

type LocaleContext = {
  setLocale?: (locale: string) => void
  questions?: Record<string, string>
  setRtl?: (rtl: boolean) => void
}
const Context = createContext<LocaleContext>({})

export const useIntlContext = (): LocaleContext => {
  return useContext(Context)
}

const loadLocaleData = (locale: string): Record<string, string> => {
  const messages: Record<string, Record<string, string>> = lang[locale]
  const outMessages: Record<string, string> = {}
  for (const key of Object.keys(messages)) {
    outMessages[key] = messages[key].defaultMessage
  }
  return outMessages
}

const q: Record<string, Record<string, string>> = {}
let fetching = false
export function MyIntlProvider({ children, setRtl, ...props }: { children: any; setRtl: (rtl: boolean) => void }) {
  const [locale, setLocale] = useLocalStorage({ key: 'locale', defaultValue: getLocale() })
  const localeRef = useRef(locale)
  const [messages, setMessages] = useState<Record<string, string>>(loadLocaleData(locale))
  const [questions, setQuestions] = useState<Record<string, string>>()
  const _setLocale = (_locale: string) => {
    setLocale(_locale)
    setRtl(languages[_locale][2] === 'rtl')
    if (_locale !== locale && window.location.search.includes('locale='))
      // remove from url
      window.location.href = window.location.href.replace(/\?locale=[a-z]{2}/, '')
  }

  useEffect(() => {
    const fetcher = async () => {
      fetching = true
      const questionsRes = await fetch(`/lang/q/${locale}.json`)
      const questions: Record<string, string> = await questionsRes.json()
      fetching = false
      q[locale] = questions
      setQuestions(questions)
    }
    // set the messages we have now
    setMessages(loadLocaleData(locale))
    // and run the async getter if no cache exists
    if (!q[locale] && !fetching) fetcher().catch(console.error)
    // else set questions from cache
    if (q[locale]) setQuestions(q[locale])
    // reset voices hack:
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    speechSynthesis.onvoiceschanged && speechSynthesis.onvoiceschanged({} as any)
    // and keep ref up to date
    localeRef.current = locale
  }, [locale])

  return useMemo(
    () => (
      <Context.Provider value={{ setLocale: _setLocale, questions, setRtl }}>
        <IntlProvider {...props} messages={messages} locale={locale} key={locale} defaultLocale="en">
          {children}
        </IntlProvider>
      </Context.Provider>
    ),
    [locale, messages, questions],
  )
}
