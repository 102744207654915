import { Anchor, Text, useMantineTheme } from '@mantine/core'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import Joyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS } from 'react-joyride'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'
// import env from '../env'
// import languages from '../lang/languages.json'

interface Tour {
  run: boolean
  idx: number
  length: number
}

// interface MyStep extends Step {
//   skip?: () => boolean
// }

function logGroup(type: string, data: any) {
  console.groupCollapsed(type)
  console.log(data)
  console.groupEnd()
}

const length = 6
export const useTour = () => {
  const [tour, setTour] = useLocalStorage<Tour>('ui-tour', {
    idx: 0,
    run: true,
    length,
  })
  const _setTour = useCallback(
    (_tour: Partial<Tour>, noReset = false) => {
      // reset idx when re-playing and idx is at the end
      const next = { ...tour, ..._tour }
      if (!noReset && next.idx === length) {
        next.idx = 0
      }
      if (tour.idx === length) next.run = true
      setTour(next)
    },
    [tour],
  )
  return [tour, _setTour] as [Tour, (tour: Partial<Tour>, noReset?: boolean) => void]
}

export default function Tour() {
  const [tour, setTour] = useTour()
  const { idx, run } = tour
  const location = useLocation()
  const theme = useMantineTheme()
  // hack to continue tour after path change:
  const ref = useRef(location)
  useEffect(() => {
    const trigger = location !== ref.current && idx < length
    if (trigger) setTour({ run: true })
    ref.current = location
  }, [location])

  // const steps: MyStep[] = [
  const steps: any[] = [
    {
      //1
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Welcome to AI Assistant!" />
          </Text>
          <Text fz="xs">
            <FormattedMessage
              defaultMessage="This is a DEMO app that answers questions about Utrecht, based on recent information from several websites. If the AI is overwhelmed due to traffic, then please be patient and try again later."
              values={{
                b: () => <Anchor href="mailto:support@ai-assistent.app">support@ai-assistent.app</Anchor>,
              }}
            />
          </Text>
        </>
      ),
      placement: 'center',
      target: 'body',
      disableBeacon: true,
    },
    {
      //2
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Choose language" />
          </Text>
          <Text fz="xs">
            <FormattedMessage
              defaultMessage="This helps the AI interpret your text and voice. Can't find your language? <b>Let us know!</b>"
              values={{
                b: () => <Anchor href="mailto:support@ai-assistent.app">support@ai-assistent.app</Anchor>,
              }}
            />
          </Text>
        </>
      ),
      target: '[data-id="header-locale"]',
      disableBeacon: true,
    },
    {
      //3
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Turn on microphone?" />
          </Text>
          <Text fz="xs">
            <FormattedMessage defaultMessage="Speech recognition works great nowadays :)" />
          </Text>
        </>
      ),
      target: '[data-id="input-microphone"]',
      disableBeacon: true,
    },
    // {
    //   //4
    //   content: (
    //     <>
    //       <Text fz="xl">
    //         <FormattedMessage defaultMessage="Match input language?" />
    //       </Text>
    //       <Text fz="xs">
    //         <FormattedMessage
    //           defaultMessage="If checked, the AI will try to find documentation in the chosen language (<p>English</p>)."
    //           values={{
    //             p: () => languages[locale][1],
    //           }}
    //         />
    //       </Text>
    //     </>
    //   ),
    //   target: '[data-id="input-match-lang"]',
    //   disableBeacon: true,
    //   skip: () => locale === env.REACT_APP_DOCS_LANG_CODE,
    // },
    {
      //4
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Autoplay audio?" />
          </Text>
          <Text fz="xs">
            <FormattedMessage defaultMessage="Try it! It's fun and works great nowadays :)" />
          </Text>
        </>
      ),
      target: '[data-id="quick-setting-autoplay"]',
      disableBeacon: true,
    },
    {
      //5
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Play audio once" />
          </Text>
          <Text fz="xs">
            <FormattedMessage defaultMessage="If you like it you can always turn on autoplay!" />
          </Text>
        </>
      ),
      target: '[data-id="message-play"]',
      disableBeacon: true,
    },
    {
      //6
      content: (
        <>
          <Text fz="xl">
            <FormattedMessage defaultMessage="Regenerate answer" />
          </Text>
          <Text fz="xs">
            <FormattedMessage defaultMessage="If you don't like the answer, pressing regenerate will provide a different one." />
          </Text>
        </>
      ),
      target: '[data-id="message-regenerate"]',
      disableBeacon: true,
    },
  ]
  // ].filter((i) => {
  //   const needed = i.skip ? !i.skip() : true
  //   return needed
  // })

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, index, lifecycle } = data
    logGroup(type, data)
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    const next = { ...tour }
    if ((action === ACTIONS.NEXT || action === ACTIONS.PREV) && type === EVENTS.STEP_AFTER) {
      next.idx = index + (action === ACTIONS.PREV ? -1 : 1)
    } else if (action === ACTIONS.CLOSE && lifecycle === LIFECYCLE.COMPLETE) {
      next.run = false
    } else if (type === EVENTS.TARGET_NOT_FOUND) {
      next.run = false
    } else if (finishedStatuses.includes(status)) {
      next.idx = steps.length
      next.run = false
    } else if (lifecycle === LIFECYCLE.COMPLETE && type === EVENTS.STEP_AFTER) {
      next.idx = index + 1
    }
    JSON.stringify(tour) !== JSON.stringify(next) && setTour(next, true)
  }

  return useMemo(
    () =>
      idx >= steps.length ? null : (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          run={run}
          stepIndex={idx}
          debug
          styles={{
            options: {
              // arrowColor: theme.white,
              // backgroundColor: theme.white,
              // overlayColor: theme.fn?.lighten(theme.primaryColor!, 0.4),
              primaryColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
              textColor: theme.black,
              // width: 900,
              zIndex: 15,
            },
          }}
          locale={{
            back: (
              <FormattedMessage
                defaultMessage="Back"
                description="Label for a button that takes the user back one step in the tour."
              />
            ),
            close: <FormattedMessage defaultMessage="Close" description="Label for a button that closes the tour." />,
            last: (
              <FormattedMessage
                defaultMessage="Last"
                description="Label for a button that appears when the user has finished the tour."
              />
            ),
            next: (
              <FormattedMessage
                defaultMessage="Next"
                description="Label for a button that takes the user to the next step of the tour."
              />
            ),
            open: (
              <FormattedMessage
                defaultMessage="Open"
                description="Label for a button that appears when starting the tour."
              />
            ),
            skip: <FormattedMessage defaultMessage="Skip" description="Label for a button to skip the tour." />,
          }}
        />
      ),
    [idx, run, steps, window.location.pathname],
  )
}
