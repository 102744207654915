import EventEmitter from 'events'
import { OptionsManager } from '../options'
import { PluginContext } from '../plugins/plugin-context'
import { pluginRunner } from '../plugins/plugin-runner'
import { EventEmitterAsyncIterator } from '../utils/event-emitter-async-iterator'
import { createApiChatCompletion, createApiChatTitle } from './api'
import { Chat, Message, OpenAIMessage, getOpenAIMessageFromMessage } from './types'
import { YChat } from './y-chat'

export class ReplyRequest extends EventEmitter {
  public error = false
  private mutatedMessages: OpenAIMessage[]
  private lastChunkReceivedAt = 0
  private timer: NodeJS.Timer | undefined
  private done = false
  private content = ''
  private sources = ''
  private cancelSSE: any

  constructor(
    private chat: Chat,
    private yChat: YChat,
    private messages: Message[],
    private replyID: string,
    private pluginOptions: OptionsManager,
    private matchInputLang: boolean,
    private bustCache: boolean,
  ) {
    super()
    this.mutatedMessages = messages.map((m) => getOpenAIMessageFromMessage(m))
    this.matchInputLang = matchInputLang
    this.bustCache = bustCache
  }

  pluginContext = (pluginID: string) =>
    ({
      getOptions: () => {
        return this.pluginOptions.getAllOptions(pluginID, this.chat.id)
      },

      getCurrentChat: () => {
        return this.chat
      },

      createChatTitle: async (messages: OpenAIMessage[]) => {
        return await createApiChatTitle(messages)
      },

      setChatTitle: (title: string) => {
        this.yChat.title = title
      },
    } as PluginContext)

  private scheduleTimeout() {
    this.lastChunkReceivedAt = Date.now()

    clearInterval(this.timer)

    this.timer = setInterval(() => {
      const sinceLastChunk = Date.now() - this.lastChunkReceivedAt
      if (sinceLastChunk > 30000 && !this.done) {
        this.onError('no response from OpenAI in the last 30 seconds')
      }
    }, 2000)
  }

  public async execute() {
    try {
      this.scheduleTimeout()

      await pluginRunner('preprocess-model-input', this.pluginContext, async (plugin) => {
        const output = await plugin.preprocessModelInput(this.mutatedMessages)
        this.mutatedMessages = output.messages
        this.lastChunkReceivedAt = Date.now()
      })

      const { emitter, cancel } = createApiChatCompletion(this.mutatedMessages, this.matchInputLang, this.bustCache)
      this.cancelSSE = cancel

      const eventIterator = new EventEmitterAsyncIterator<string>(emitter, ['data', 'done', 'error', 'sources'])

      for await (const event of eventIterator) {
        const { eventName, value } = event

        switch (eventName) {
          case 'data':
            await this.onData(value)
            break

          case 'sources':
            this.onSources(value)
            break

          case 'done':
            await this.onDone()
            break

          case 'error':
            if (!this.content || !this.done) {
              this.onError(value)
            }
            break
        }
      }
    } catch (e: any) {
      console.error(e)
      this.onError(e.message as string)
      this.error = true
    }
  }

  public async onData(value: any) {
    if (this.done) {
      return
    }

    this.lastChunkReceivedAt = Date.now()

    this.content = value

    await pluginRunner('postprocess-model-output', this.pluginContext, async (plugin) => {
      const output = await plugin.postprocessModelOutput(
        {
          role: 'assistant',
          content: this.content,
        },
        this.mutatedMessages,
        false,
      )

      this.content = output.content
    })

    this.yChat.setPendingMessageContent(this.replyID, this.content)
  }

  public onSources(value: any) {
    if (this.done) {
      return
    }

    this.sources = value
  }

  public async onDone() {
    if (this.done) {
      return
    }
    clearInterval(this.timer)
    this.lastChunkReceivedAt = Date.now()
    this.done = true
    this.emit('done')

    this.yChat.onMessageDone(this.replyID)

    await pluginRunner('postprocess-model-output', this.pluginContext, async (plugin) => {
      const output = await plugin.postprocessModelOutput(
        {
          role: 'assistant',
          content: this.content,
        },
        this.mutatedMessages,
        true,
      )

      this.content = output.content
    })
    if (this.sources) this.yChat.setMessageSources(this.replyID, this.sources)
    this.yChat.setMessageContent(this.replyID, this.content)
  }

  public onError(_: string) {
    if (this.done) {
      return
    }
    this.done = true
    this.error = true
    this.emit('done')
    clearInterval(this.timer)
    this.cancelSSE?.()

    // this.content += `\n\nI'm sorry, I'm having trouble connecting to OpenAI (${
    //   error || 'no response from the API'
    // }). Please try again with "Regenerate".`
    // this.content = this.content.trim()

    // this.yChat.setMessageContent(this.replyID, this.content)
    this.yChat.onMessageDone(this.replyID)
  }

  public onCancel() {
    clearInterval(this.timer)
    this.done = true
    this.yChat.onMessageDone(this.replyID)
    this.cancelSSE?.()
    this.emit('done')
  }

  // private setMessageContent(content: string) {
  //     const text = this.yChat.content.get(this.replyID);
  //     if (text && text.toString() !== content) {
  //         text?.delete(0, text.length);
  //         text?.insert(0, content);
  //     }
  // }
}
