import { OpenAIMessage } from '../chat/types'
import { PluginContext } from './plugin-context'
import { PluginDescription } from './plugin-description'

export default class Plugin<T = any> {
  constructor(public context?: PluginContext) {}

  initialize() {}

  describe(): PluginDescription {
    throw new Error('not implemented')
  }

  get options(): T | undefined {
    return this.context?.getOptions()
  }

  preprocessModelInput(messages: OpenAIMessage[]): Promise<{
    messages: OpenAIMessage[]
  }> {
    return Promise.resolve({ messages })
  }

  postprocessModelOutput(message: OpenAIMessage, _context: OpenAIMessage[], _done: boolean): Promise<OpenAIMessage> {
    return Promise.resolve(message)
  }
}
