import { OpenAIMessage } from '../core/chat/types'
import Plugin from '../core/plugins'
import { PluginDescription } from '../core/plugins/plugin-description'

export class TitlePlugin extends Plugin {
  describe(): PluginDescription {
    return {
      id: 'titles',
      name: 'Title Generator',
      options: [],
    }
  }

  async postprocessModelOutput(
    message: OpenAIMessage,
    contextMessages: OpenAIMessage[],
    done: boolean,
  ): Promise<OpenAIMessage> {
    if (!done || this.context?.getCurrentChat().title) return message
    const messages = [...contextMessages.filter((m) => m.role === 'user' || m.role === 'assistant'), message]
    const output = await this.context?.createChatTitle(messages)

    if (!output || output === 'N/A') {
      return message
    }

    await this.context?.setChatTitle(output)

    return message
  }
}
