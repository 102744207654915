import ar from './ar.json'
import bg from './bg.json'
import cs from './cs.json'
import da from './da.json'
import de from './de.json'
import el from './el.json'
import en from './en.json'
import es from './es.json'
import et from './et.json'
import fi from './fi.json'
import fr from './fr.json'
import hr from './hr.json'
import hu from './hu.json'
import it from './it.json'
import lt from './lt.json'
import lv from './lv.json'
import nl from './nl.json'
import pl from './pl.json'
import pt from './pt.json'
import ro from './ro.json'
import sk from './sk.json'
import sl from './sl.json'
import sv from './sv.json'
import tr from './tr.json'
import uk from './uk.json'
const ex: Record<string, Record<string, Record<string, string>>> = {
  ar,
  bg,
  cs,
  da,
  de,
  el,
  en,
  es,
  et,
  fi,
  fr,
  hr,
  hu,
  it,
  lt,
  lv,
  nl,
  pl,
  pt,
  ro,
  sk,
  sl,
  sv,
  tr,
  uk,
}
export default ex
