declare global {
  interface Window {
    env: any
    ga: any
  }
}

export type EnvType = {
  REACT_APP_COLOR: string
  REACT_APP_DOCS_LANG: string
  REACT_APP_DOCS_LANG_CODE: string
  REACT_APP_DOCS_MULTILANG: string
}

const env: EnvType = { ...process.env, ...window.env }

export default env
