import styled from '@emotion/styled'
import {
  Burger,
  Button,
  ButtonProps,
  Drawer,
  Header,
  MantineTheme,
  Menu,
  Select,
  rem,
  useMantineColorScheme,
} from '@mantine/core'
import { useDisclosure, useHotkeys } from '@mantine/hooks'
import { useSpotlight } from '@mantine/spotlight'
import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage, useMediaQuery } from 'usehooks-ts'
import { backend } from '../core/backend'
import { useAppContext } from '../core/context'
import { useAppDispatch } from '../store'
import { setTab } from '../store/settings-ui'
import { openLoginModal, openSignupModal } from '../store/ui'
import { getMainColor } from '../theme'
import { langCodes, useIntlContext } from './intl'
import { usePage } from './page'
import RecentChats from './sidebar'
import { useTour } from './tour'

export const HEADER_HEIGHT = rem(44)
const offset = window.navigator.userAgent.includes('Safari') && !window.navigator.userAgent.includes('Chrome') ? 4 : 8

const Banner = styled.div`
  min-height: 2.618rem;
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-size: 80%;
  padding: 0.5rem;
  cursor: pointer;
`

const StyledHeader = styled(Header)<{ theme?: MantineTheme | undefined }>(
  ({ theme }) => `
  display: flex;
  justifyContent: space-between 
  alignItems: center;
  padding: 4px 6px;

  .title {
    padding: 6px ${theme.spacing.md} 0 ${theme.spacing.md};
    flex: 1;
    white-space: nowrap;
  }
  // buttons
  .mantine-Button-label {
    color: ${theme.colorScheme === 'dark' ? theme.white : getMainColor(theme)} !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
  }
  //select
  .mantine-Select-input {
    height: calc(${HEADER_HEIGHT} - ${offset}px);
    font-size: 1rem;
    color: ${theme.colorScheme === 'dark' ? theme.white : getMainColor(theme)};

  }
  .mantine-Select-root {
    margin-right: 0.4rem;
    .mantine-Select-dropdown {
      top: ${HEADER_HEIGHT} !important;
    }
  }
  .mantine-InputWrapper-root.mantine-Select-root:hover {
    background-color: ${theme.colorScheme === 'dark' ? getMainColor(theme) : theme.colors[theme.primaryColor][1]};
    border-radius: 2px;
  }
  // menu
  .mantine-Menu-item {
    .mantine-Menu-itemIcon {
      width: 1rem;
      color: ${theme.colorScheme === 'dark' ? theme.white : getMainColor(theme)};
    }
  }

  @media (max-width: 50em) {
    .mantine-Button-label span {
      display: none;
    }
    .mantine-Button-root {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }
  }
`,
)

const StyledDrawer = styled(Drawer)`
  .mantine-Drawer-overlay,
  .mantine-Drawer-inner {
    top: ${HEADER_HEIGHT};
  }
  .mantine-Drawer-close {
    display: none;
  }
  .mantine-Drawer-body {
    // height: calc(100vh - ${HEADER_HEIGHT}) !important;
  }
  .mantine-Drawer-body {
    padding-left: 0;
    padding-right: 0;
  }
`

function HeaderButton(props: ButtonProps & { icon?: string; onClick?: any; children?: any; component?: any }) {
  const { children, icon, variant, ..._props } = props
  return (
    <Button variant={variant || 'subtle'} {..._props}>
      {icon && <i className={`${icon}`} />}
      {children && <span>{children}</span>}
    </Button>
  )
}

export interface HeaderProps {
  onShare?: () => void | Promise<void>
  share?: boolean
  canShare?: boolean
}

export default function Layout({ children, ...props }) {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const { authenticated, sessionExpired } = useAppContext()
  const navigate = useNavigate()
  const { openSpotlight } = useSpotlight()
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const { formatMessage, locale } = useIntl()
  const { setLocale } = useIntlContext()
  const [currentTab] = useLocalStorage('settings-ui-tab', 'ui')
  const [tour, setTour] = useTour()
  const { isHome } = usePage()

  const [sidebarOpened, { toggle: toggleSidebar }] = useDisclosure(false)
  const sidebarBurgerLabel = sidebarOpened
    ? formatMessage({ defaultMessage: 'Close sidebar' })
    : formatMessage({ defaultMessage: 'Open sidebar' })

  const [menuOpened, setMenuOpened] = useState(false)
  const menuBurgerLabel = menuOpened
    ? formatMessage({ defaultMessage: 'Close menu' })
    : formatMessage({ defaultMessage: 'Open menu' })

  const onNewChat = useCallback(() => {
    setLoading(true)
    navigate(`/`)
    setLoading(false)
    setTimeout(() => document.querySelector<HTMLTextAreaElement>('#message-input')?.focus(), 100)
  }, [navigate])

  const openSettings = useCallback(() => {
    dispatch(setTab(currentTab))
  }, [currentTab, dispatch])

  const signIn = useCallback(() => {
    if ((window as any).AUTH_PROVIDER !== 'local') {
      backend.current?.signIn()
    } else {
      dispatch(openLoginModal())
    }
  }, [dispatch])

  const signUp = useCallback(() => {
    if ((window as any).AUTH_PROVIDER !== 'local') {
      backend.current?.signIn()
    } else {
      dispatch(openSignupModal())
    }
  }, [dispatch])

  const startTour = (run = true) => {
    setTour({ ...tour, run })
  }

  useHotkeys([['c', onNewChat]])

  const localeWidth = 36
  const isMobile = useMediaQuery(`(max-width: 30rem)`)

  return useMemo(
    () => (
      <>
        {sessionExpired && (
          <Banner onClick={signIn}>
            <FormattedMessage defaultMessage="You have been signed out. Click here to sign back in." />
          </Banner>
        )}
        <StyledDrawer
          className="sidebar"
          opened={sidebarOpened}
          onClose={toggleSidebar}
          position="left"
          title={<FormattedMessage defaultMessage={'Chat History'} description="Heading for the chat history screen" />}
        >
          <RecentChats />
        </StyledDrawer>
        <StyledHeader height={HEADER_HEIGHT}>
          <Burger
            data-id="burger"
            opened={sidebarOpened}
            onClick={() => toggleSidebar()}
            aria-label={sidebarBurgerLabel}
            transitionDuration={200}
          />
          <p className="title">
            {isMobile ? (
              <FormattedMessage defaultMessage="Ask AI" description="Header title tag" />
            ) : (
              <FormattedMessage defaultMessage="Ask AI Assistant" description="HTML title tag" />
            )}
          </p>
          <div className="spacer" />
          {backend.current && !props.share && props.canShare && typeof navigator.share !== 'undefined' && (
            <HeaderButton icon="fa fa-share" onClick={props.onShare}>
              <FormattedMessage
                defaultMessage="share"
                description="Label for the button used to create a public share URL for a chat log"
              />
            </HeaderButton>
          )}
          {!isHome && (
            <HeaderButton data-id="header-newchat" icon="fa fa-plus" onClick={onNewChat} loading={loading}>
              <FormattedMessage
                defaultMessage="new chat"
                description="Label for the button used to start a new chat session"
              />
            </HeaderButton>
          )}
          <Select
            title={formatMessage({
              defaultMessage: 'Language selection',
              description: 'Label for the select input used to change the language',
            })}
            size={'md'}
            data-id="header-locale"
            value={locale}
            onChange={(code) => setLocale!(code as string)}
            data={langCodes}
            zIndex={1000}
            styles={(theme) => ({
              wrapper: {
                width: localeWidth + 2,
                color: theme.colorScheme === 'dark' ? theme.white : theme.primaryColor,
              },
              input: {
                paddingLeft: 8,
              },
              item: {
                width: localeWidth - 8,
                padding: '2px 4px',
              },
              itemsWrapper: {
                width: localeWidth,
              },
              dropdown: {
                width: localeWidth,
              },
            })}
            maxDropdownHeight={500}
            nothingFound="..."
            searchable={false}
            variant="unstyled"
            rightSection={<></>}
            rightSectionWidth={1}
          />
          <Menu shadow="md" width={200} onChange={(opened) => setMenuOpened(opened)}>
            <Menu.Target>
              <Burger
                data-id="menu"
                opened={menuOpened}
                onClick={() => {
                  return
                }}
                aria-label={menuBurgerLabel}
                transitionDuration={200}
              />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={
                  <i className={`fa-solid fa-${tour.idx < tour.length ? (tour.run ? 'pause' : 'play') : 'play'}`} />
                }
                onClick={() => startTour(!tour.run)}
              >
                {tour.run && tour.idx < tour.length ? (
                  <FormattedMessage
                    defaultMessage="Stop Tour"
                    description="Label for the button used to stop the tour"
                  />
                ) : !tour.run && tour.idx < tour.length ? (
                  <FormattedMessage
                    defaultMessage="Resume Tour"
                    description="Label for the button used to resume the tour"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Start Tour"
                    description="Label for the button used to start the tour"
                  />
                )}
              </Menu.Item>
              <Menu.Item
                icon={<i className={`fa-solid fa-${colorScheme === 'dark' ? 'moon' : 'sun'}`} />}
                onClick={() => toggleColorScheme()}
              >
                {colorScheme === 'dark' ? (
                  <FormattedMessage
                    defaultMessage="Dark mode"
                    description="Label for the button used to go to light mode"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="Light mode"
                    description="Label for the button used to go to dark mode"
                  />
                )}
              </Menu.Item>
              <Menu.Item icon={<i className="fa fa-search" />} onClick={() => openSpotlight()}>
                <FormattedMessage defaultMessage="Search" description="Label for the button used to search messages" />
              </Menu.Item>
              <Menu.Item icon={<i className="fa fa-gear" />} onClick={openSettings}>
                <FormattedMessage defaultMessage="Settings" description="Label for the button used to open settings" />
              </Menu.Item>
              <Menu.Item icon={<i className="fa-solid fa-info" />} onClick={() => navigate('/about')}>
                <FormattedMessage
                  defaultMessage="About"
                  description="Label for the button used to go to the about page"
                />
              </Menu.Item>
              <Menu.Divider />
              {authenticated ? (
                <Menu.Item
                  data-id="usermenu-logout"
                  onClick={() => backend.current?.logout()}
                  icon={<i className="fas fa-sign-out-alt" />}
                >
                  <FormattedMessage defaultMessage={'Sign out'} />
                </Menu.Item>
              ) : (
                <Menu.Item
                  data-id="usermenu-signin"
                  onClick={localStorage.getItem('registered') ? signIn : signUp}
                  icon={<i className="fa-solid fa-right-to-bracket" />}
                >
                  <FormattedMessage
                    defaultMessage="Sign in <h>to sync</h>"
                    description="Label for sign in button, which indicates that the purpose of signing in is to sync your data between devices. Less important text inside <h> tags is hidden on small screens."
                    values={{
                      h: (chunks: any) => <span className="hide-on-mobile">{chunks}</span>,
                    }}
                  />
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </StyledHeader>
        {children}
      </>
    ),
    [
      colorScheme,
      isMobile,
      locale,
      localeWidth,
      sidebarOpened,
      menuOpened,
      props.share,
      props.canShare,
      props.onShare,
      openSettings,
      onNewChat,
      loading,
      authenticated,
      sessionExpired,
      openSpotlight,
      signIn,
      signUp,
      tour,
    ],
  )
}
