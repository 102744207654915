import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { ChatManager } from './'
import { Backend, User } from './backend'

export interface Context {
  authenticated: boolean
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
  chatManager: ChatManager
  sessionExpired: boolean
  user: User | null
  env: Record<string, string>
}

const AppContext = React.createContext<Context>({} as Context)

const chatManager = new ChatManager()
const backend = new Backend(chatManager)

function useCreateAppContext(): Context {
  const [authenticated, setAuthenticated] = useState(backend?.isAuthenticated || false)
  const [error, setError] = useState(false)
  const [wasAuthenticated, setWasAuthenticated] = useState(backend?.isAuthenticated || false)
  const { locale } = useIntl()

  useEffect(() => {
    chatManager.on('y-update', (update: Uint8Array) => backend?.receiveYUpdate(update))
    chatManager.on('error', (err: boolean) => setError(err))
  }, [chatManager])

  // hack to reset voice plugin settings to defaults:
  useEffect(() => {
    localStorage.removeItem('tts.service')
    localStorage.removeItem('web-speech.voice')
    chatManager.options.reloadOptions()
  }, [locale])

  const updateAuth = useCallback(
    (authenticated: boolean) => {
      setAuthenticated(authenticated)
      if (authenticated && backend.user) {
        chatManager.login(backend.user.email || backend.user.id)
      }
      if (authenticated) {
        setWasAuthenticated(true)
        localStorage.setItem('registered', 'true')
      }
    },
    [chatManager],
  )

  useEffect(() => {
    updateAuth(backend?.isAuthenticated || false)
    backend?.on('authenticated', updateAuth)
    return () => {
      backend?.off('authenticated', updateAuth)
    }
  }, [updateAuth])

  const context = useMemo<Context>(
    () => ({
      authenticated,
      error,
      setError,
      sessionExpired: !authenticated && wasAuthenticated,
      user: backend.user,
      chatManager,
      env: backend.env,
    }),
    [error, authenticated, backend.env, wasAuthenticated],
  )

  return context
}

export function useAppContext() {
  return React.useContext(AppContext)
}

export function AppContextProvider(props: { children: React.ReactNode }) {
  const context = useCreateAppContext()
  return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>
}
