import TTSPlugin from './tts-plugin'
import { Voice } from './types'

export default class DirectTTSPlugin<T = any> extends TTSPlugin<T> {
  speak(_text: string, _voice?: Voice) {
    throw new Error('not implemented.')
  }

  stop() {
    throw new Error('not implemented.')
  }
}
