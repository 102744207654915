import { getLocale } from '../components/intl'
import { PluginDescription } from '../core/plugins/plugin-description'
import DirectTTSPlugin from '../core/tts/direct-tts-plugin'
import { Voice } from '../core/tts/types'

export interface WebSpeechPluginOptions {
  voice: string | null
}

/**
 * Plugin for integrating with the built-in Text-to-Speech service on the user's device via
 * the Web Speech Synthesis API.
 *
 * If you want to add a plugin to support a cloud-based TTS service, this class is probably
 * not relevant. Consider using ElevenLabsPlugin as an example instead.
 */
export default class WebSpeechPlugin extends DirectTTSPlugin<WebSpeechPluginOptions> {
  static voices: Voice[] = []

  private rejections: any[] = []
  private speaking = 0

  initialize() {
    this.getVoices()
    speechSynthesis.onvoiceschanged = () => this.getVoices()
  }

  describe(): PluginDescription {
    const id = 'web-speech'
    return {
      id,
      name: "Your Browser's Built-In Text-to-Speech",
      options: [
        {
          id: 'voice',
          defaultValue: () => this.getCurrentVoice()?.id,
          displayOnSettingsScreen: 'speech',
          displayAsSeparateSection: true,
          renderProps: (value, options) => ({
            type: 'select',
            label: 'voice',
            options: WebSpeechPlugin.voices.map((v) => ({
              label: v.name!,
              value: v.id,
            })),
            hidden: options.getOption('tts', 'service') !== id,
          }),
        },
      ],
    }
  }

  getVoices() {
    const voices = window.speechSynthesis.getVoices()
    const locale = getLocale()
    WebSpeechPlugin.voices = voices
      .filter((v) => v.lang?.substring(0, 2) === locale)
      .map((v) => ({
        service: 'web-speech',
        id: v.name,
        name: v.name,
        lang: v.lang,
      }))
    return WebSpeechPlugin.voices
  }

  getCurrentVoice(): Voice {
    const voiceID = this.options?.voice
    const locale = getLocale()

    const voice = WebSpeechPlugin.voices.find((v) => v.id === voiceID)

    if (voice && voice.lang?.substring(0, 2) === locale) {
      return voice
    }

    return WebSpeechPlugin.voices[0]
  }

  speak(text: string, inVoice?: Voice): void {
    const voice = inVoice ?? this.getCurrentVoice()
    const utterance = new SpeechSynthesisUtterance(text)
    utterance.rate = 0.85
    utterance.voice = window.speechSynthesis.getVoices().find((v) => v.name === voice.id)!

    utterance.onstart = () => {
      this.speaking += 1
    }
    utterance.onend = () => {
      this.speaking -= 1
    }

    speechSynthesis.speak(utterance)
  }

  pause() {
    if (!speechSynthesis.paused) {
      speechSynthesis.pause()
    }
  }

  resume() {
    if (speechSynthesis.paused) {
      speechSynthesis.resume()
    }
  }

  stop() {
    speechSynthesis.cancel()
    this.speaking = 0
    for (const reject of this.rejections) {
      reject('cancelled')
    }
    this.rejections = []
  }

  isSpeaking() {
    return this.speaking > 0
  }
}
