import { ButtonStylesParams, LoaderProps, MantineTheme } from '@mantine/core'
import env from './env'

export function getHoverColor(theme: MantineTheme, params: any = {}) {
  return theme.colors[params.color || theme.primaryColor][theme.colorScheme === 'dark' ? 9 : 1]
}

export function getMainColor(theme: MantineTheme, params: any = {}, level?: number) {
  return theme.colors[params.color || theme.primaryColor][level ?? theme.fn.primaryShade()]
}

const theme: Partial<MantineTheme> = {
  primaryColor: env.REACT_APP_COLOR,
  components: {
    Button: {
      // Subscribe to theme and component params
      styles: (theme, params: ButtonStylesParams, { variant }) => ({
        root: {
          backgroundColor: variant === 'filled' ? getMainColor(theme, params) : undefined,
          color:
            variant === 'subtle'
              ? theme.colorScheme === 'dark'
                ? theme.white
                : getMainColor(theme, params)
              : undefined,
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: variant === 'subtle' ? getHoverColor(theme, params) : undefined,
            },
          },
        },
      }),
    },
    Loader: {
      styles: (theme, params: LoaderProps) => ({
        root: {
          color: getMainColor(theme, params),
        },
      }),
    },
  },
  globalStyles: (theme) => ({
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },

    body: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.black : theme.white,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    },

    a: {
      color: `${theme.colorScheme === 'dark' ? theme.white : getMainColor(theme)} !important`,
      textDecoration: 'none',
      '&:hover, &:focus, &:active': {
        textDecoration: 'underline',
      },
    },
    '@media (hover: hover)': {
      '.mantine-Menu-item:hover': {
        color: `${theme.colorScheme === 'dark' ? theme.white : theme.black}`,
        backgroundColor: getHoverColor(theme),
      },
      '.mantine-ActionIcon-root:hover': {
        backgroundColor: getHoverColor(theme),
      },
    },
    '.selectable.selected': {
      color: getMainColor(theme),
    },
  }),
}

export default theme
