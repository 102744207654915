import styled from '@emotion/styled'
import { ActionIcon, Button, Loader, MantineTheme, Menu, Textarea, useMantineTheme } from '@mantine/core'
import { useDisclosure, useElementSize } from '@mantine/hooks'
import { useModals } from '@mantine/modals'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { backend } from '../core/backend'
import { useAppContext } from '../core/context'
import { ChatSearchResults } from '../core/search'
import { useMessage } from './message'

const Empty = styled.p`
  text-align: center;
  font-size: 0.8rem;
  padding: 2rem;
`

const ChatList = styled.div`
  line-height: 2;
  .mantine-ActionIcon-root {
    display: inline;
    opacity: 0;
    margin: auto;
    margin-right: 0;
    @media (hover: none) {
      opacity: 1;
    }
  }
`

const StyledChatListItem = styled.div<{ theme?: MantineTheme | undefined }>(
  ({ theme }) => `
  display: flex;
  justifyContent: space-between;
  padding-left: 1rem;
  padding-right: 1rem;

  @media (hover: hover) {
    &:hover {
      .mantine-ActionIcon-root {
        opacity: 1;
      }
    }
  }
  &.selected {
    span, .mantine-ActionIcon-root {
      color: ${theme?.white};
      background-color: transparent;
    }
    background-color: ${theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors[theme.primaryColor][6]};
  }
`,
)

function ChatListItem({ chat, selected }: { chat: ChatSearchResults; selected: boolean }) {
  const theme = useMantineTheme()
  const c = chat
  const { chatManager } = useAppContext()
  const modals = useModals()
  const navigate = useNavigate()
  const { locale, formatMessage } = useIntl()

  const onDelete = useCallback(
    (e?: React.MouseEvent) => {
      e?.preventDefault()
      e?.stopPropagation()

      modals.openConfirmModal({
        title: formatMessage({ defaultMessage: 'Are you sure you want to delete this chat?' }),
        children: (
          <p style={{ lineHeight: 1.7 }}>
            {formatMessage(
              {
                defaultMessage: 'The chat <h>title</h> will be permanently deleted. This cannot be undone.',
              },
              {
                h: () => <b>{c.title}</b>,
              },
            )}
          </p>
        ),
        labels: {
          confirm: formatMessage({ defaultMessage: 'Delete permanently' }),
          cancel: formatMessage({ defaultMessage: 'Cancel' }),
        },
        confirmProps: {
          color: theme.primaryColor,
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm: async () => {
          try {
            await backend.current?.deleteChat(c.chatID)
            chatManager.deleteChat(c.chatID)
            navigate('/')
          } catch (e) {
            console.error(e)
            modals.openConfirmModal({
              title: formatMessage({ defaultMessage: 'Something went wrong' }),
              children: (
                <p style={{ lineHeight: 1.7 }}>
                  {' '}
                  {formatMessage(
                    {
                      defaultMessage: 'The chat <h>title</h> could not be deleted.',
                    },
                    {
                      h: () => `<b>${c.title}</b>`,
                    },
                  )}
                </p>
              ),
              labels: {
                confirm: formatMessage({ defaultMessage: 'Try again' }),
                cancel: formatMessage({ defaultMessage: 'Cancel' }),
              },
              onConfirm: () => onDelete(),
            })
          }
        },
      })
    },
    [c.chatID, c.title, locale, modals],
  )

  const onRename = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      // Display a modal with a TextInput
      modals.openModal({
        title: <FormattedMessage defaultMessage={'Rename this chat'} />,
        children: (
          <div>
            <Textarea id="chat-title" defaultValue={c.title} maxLength={500} autosize required />
            <Button
              fullWidth
              variant="filled"
              style={{ marginTop: '1rem' }}
              onClick={() => {
                const title = document.querySelector<HTMLInputElement>('#chat-title')?.value?.trim()
                const ychat = chatManager.doc.getYChat(c.chatID)
                if (ychat && title && title !== ychat?.title) {
                  ychat.title = title
                }
                modals.closeAll()
              }}
            >
              <FormattedMessage
                defaultMessage="Save changes"
                description="Label for a button that appears when the user is editing something, to save the changes"
              />
            </Button>
          </div>
        ),
      })
    },
    [c.chatID, c.title, locale, modals],
  )

  const [menuOpened, { close, toggle }] = useDisclosure(false)

  const text = c.title || (
    <FormattedMessage defaultMessage={'Untitled'} description="default title for untitled chat sessions" />
  )

  return (
    <StyledChatListItem className={selected ? 'selected' : ''}>
      {selected ? <span>{text}</span> : <Link to={`/chat/${c.chatID}`}>{text}</Link>}
      <span className="spacer" />
      <Menu opened={menuOpened} onClose={() => close()}>
        <Menu.Target>
          <ActionIcon size="sm" onClick={toggle}>
            <i className="fas fa-ellipsis" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={onRename} icon={<i className="fa fa-edit" />}>
            <FormattedMessage defaultMessage={'Rename this chat'} />
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={onDelete} color="red" icon={<i className="fa fa-trash" />}>
            <FormattedMessage defaultMessage={'Delete this chat'} />
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </StyledChatListItem>
  )
}

export default function RecentChats() {
  const { chatManager } = useAppContext()
  const { currentChat } = useMessage()
  const currentChatID = currentChat.chat?.id
  const recentChats = chatManager.searchChats('')

  useEffect(() => {
    if (currentChatID) {
      const el = document.querySelector(`[data-chat-id="${currentChatID}"]`)
      if (el) {
        el.scrollIntoView()
      }
    }
  }, [currentChatID])

  const { ref, width } = useElementSize()
  const [version, setVersion] = useState(0)
  // next bit is a hack to force a re-render when the chat manager updates:
  const update = () => {
    setVersion((v) => v + 1)
  }
  useEffect(() => {
    chatManager.on('update', update)
    return () => {
      chatManager.off('update', update)
    }
  }, [chatManager])

  const synced = !backend.current || backend.current?.isSynced()

  return useMemo(
    () => (
      <div data-id="sidebar-recent-chats" ref={ref}>
        {recentChats.length > 0 && (
          <ChatList>
            {recentChats.map((c) => (
              <ChatListItem key={c.chatID} chat={c} selected={c.chatID === currentChatID} />
            ))}
          </ChatList>
        )}
        {recentChats.length === 0 && !synced && (
          <Empty>
            <Loader size="sm" variant="dots" />
          </Empty>
        )}
        {recentChats.length === 0 && synced && (
          <Empty>
            <FormattedMessage
              defaultMessage={'No chats yet.'}
              description="Message shown on the Chat History screen for new users who haven't started their first chat session"
            />
          </Empty>
        )}
      </div>
    ),
    [open, width, ref, version],
  )
}
